.desactivarPolizaAlert-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}
    .desactivarPolizaAlert-container-text {
        text-align: center;
        color: #333333;
        margin: 32px 0;
    }