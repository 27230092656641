.cotizar-container {
      padding:0;
      margin:0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      position: fixed;
}
      @media only screen and (max-width: 37.5em) {
            .cotizar-container {
                  max-height: unset;
            }}
      .cotizar-container-mainLoading {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 80%;
      }
            @media only screen and (max-width: 37.5em) {
                  .cotizar-container-mainLoading {
                        height: 100%;
                  }}
            .cotizar-container-mainLoading-lottieWrapper {
                  height: 60%;
                  width: 100%;
                  margin: 0;
            }
            .cotizar-container-mainLoading-text {
                  font-style: normal;
                  font-weight: 325;
                  font-size: 2.75rem;
                  text-align: center;
                  color: var(--primary500);
                  margin: 0;
            }
            @media only screen and (max-width: 37.5em) {
                  .cotizar-container-mainLoading-text {
                        font-size: 1rem;
                        width: 50%;
                  }}
            .cotizar-container-mainLoading-subText {
                  font-style: normal;
                  font-weight: 325;
                  font-size: 1.5rem;
                  text-align: center;
                  color: var(--primary500);
                  margin: 0;
                  margin-top: 10px;
            }
            @media only screen and (max-width: 37.5em) {
                  .cotizar-container-mainLoading-subText {
                        font-size: 1rem;
                        width: 50%;
                  }}
      .cotizar-container-helpText {
            font-style: normal;
            font-weight: bold;
            font-size: 1rem;
            color: #333333;
            margin: 0;
            text-align: center;
            align-self: center;
            margin-bottom: 20px;
      }
            .cotizar-container-helpText a {
                  font-style: normal;
                  font-weight: bold;
                  font-size: 1rem;
                  color: var(--primary500);
                  margin: 0;
                  cursor: pointer;
                  text-decoration: none;
            }
      .cotizar-container-main {
            display: flex;
            flex-direction: row;
            width: 100%;
            max-width: 1440px;
            align-self: center;
      }
            .cotizar-container-main-left {
                  width: 25%;
                  display: flex;
                  flex-direction: column;
                  margin-right: 25px;
                  height: 100%;
            }
                  @media only screen and (max-width: 37.5em) {
                        .cotizar-container-main-left {
                              display: none;
                        }}
            .cotizar-container-main-right {
                  width: 65%;
                  display: flex;
                  flex-direction: column;
                  height: 100%;
            }
                  @media only screen and (max-width: 37.5em) {
                        .cotizar-container-main-right {
                              width: 100%;
                        }}
            .cotizar-container-main-right-questionText  {
                  font-style: normal;
                  font-weight: bold;
                  font-size: 1.5rem;
                  line-height: 29px;
                  display: flex;
                  align-items: center;
                  color: var(--black);
            }
                  @media only screen and (max-width: 37.5em) {
                        .cotizar-container-main-right-questionText  {
                              font-size: 1rem;
                              margin-left: 20px;
                              margin-top: 20px;
                        }}
                  .cotizar-container-main-right-questionText h1 {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.25rem;
                        line-height: 24px;
                        color: var(--primary500);
                        margin: 0;
                  }
            .cotizar-container-main-right-categoryList{
                  display: flex;
                  width: 100%;
                  flex-direction: row;
                  flex-wrap: wrap;
            } 
                  @media only screen and (max-width: 37.5em) {
                        .cotizar-container-main-right-categoryList{
                              flex-wrap: nowrap;
                              overflow-y: scroll;
                              align-items: flex-start;
                        }}
            .cotizar-container-main-buttonWrapper {
                  display: flex;
                  margin-right: 5;
                  margin-top: auto;
                  margin-bottom: 40px;
                  width: 40%;
                  align-self: flex-end;
            }
                  @media only screen and (max-width: 37.5em) {
                        .cotizar-container-main-buttonWrapper {
                              width: 90%;
                              align-self: center;
                              margin-top: 20px;
                        }}
            .cotizar-container-main-buttonContainer {
                  display: flex;
                  flex-direction: row;
                  margin-top: auto;
                  margin-bottom: 40px;
                  width: 100%;
                  align-items: center;
                  justify-content: flex-end;
            }
                  @media only screen and (max-width: 37.5em) {
                        .cotizar-container-main-buttonContainer {
                              flex-direction: column;
                              width: 90%;
                              margin-top: 10px;
                              justify-content: center;
                              margin-left: auto;
                              margin-right: auto;
                        }}
                  .cotizar-container-main-buttonContainer-wrapper {
                        width: 40%;
                        margin-left: 15px;
                  }
                        @media only screen and (max-width: 37.5em) {
                              .cotizar-container-main-buttonContainer-wrapper {
                                    width: 100%;
                                    margin-left: 0;
                                    margin-bottom: 10px;
                              }}