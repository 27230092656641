.mainCotizar-container {
    width: 100%;
    display: flex;
    flex-direction: column;  
    overflow-x: hidden;
}
    .mainCotizar-container-titleContainer {
        display: flex;
        flex-direction: column;
        background: var(--primary500);
        width: 100%;
        padding-bottom: 50px;
    }
        @media only screen and (max-width: 37.5em) {
            .mainCotizar-container-titleContainer {
                background: var(--white);
                padding-top: 25px;
                padding-bottom: 25px;
            }}
         .mainCotizar-container-titleContainer-title {
            font-style: normal;
            font-weight: normal;
            font-size: 2.5rem;
            line-height: 48px;
            color: var(--white);
            margin: 0;
            margin-left: 50px;
        }
            @media only screen and (max-width: 37.5em) {
                .mainCotizar-container-titleContainer-title {
                    color: var(--primary500);
                    font-size: 1.5625rem;
                    text-align: center;
                    line-height: 28px;
                    margin: 0;
                    width: 70%;
                    align-self: center;
                }}
        .mainCotizar-container-titleContainer-details {
            display: none;
            flex-direction: row;
            width: 100%;
        }
            @media only screen and (max-width: 37.5em) {
                .mainCotizar-container-titleContainer-details {
                    display: flex;
                }}
            .mainCotizar-container-titleContainer-details-product {
                display: flex;
                flex-direction: column;
                width: 40%;
                margin-left: 20px;
                padding-top: 20px;
            }
                .mainCotizar-container-titleContainer-details-product-marca {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 1.0625rem;
                    color: var(--black);
                    margin: 0;
                }
                .mainCotizar-container-titleContainer-details-product-title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.375rem;
                    color: var(--black);
                    margin: 10px 0;
                }
                .mainCotizar-container-titleContainer-details-product-modelo {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 0.9375rem;
                    color: var(--black);
                    margin: 0;
                    margin-bottom: 20px;
                }
                .mainCotizar-container-titleContainer-details-product-valor {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.75rem;
                    color: var(--black);
                    margin: 0;
                    margin-bottom: 10px;
                }
                .mainCotizar-container-titleContainer-details-product-valorValue {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.9375rem;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    color: var(--black);     
                    margin: 0;
                    background: #EEEEEE;
                    opacity: 0.5;
                    border-radius: 25px;
                    padding: 10px 10px;
                    width: fit-content;
                }
            .mainCotizar-container-titleContainer-details-image {
                width: 60%;
                height: auto;
            }
    .mainCotizar-container-titleContainerMask {
        display: flex;
        background: var(--primary500);
        height: 100px;
        width: 100%;
    }
        @media only screen and (max-width: 37.5em) {
            .mainCotizar-container-titleContainerMask {
                background: var(--white);
            }}
        .mainCotizar-container-titleContainerMask-inner {
            display: flex;
            background: var(--gray50);
            height: 100px;
            width: 100%;
            border-top-right-radius: 50px;
        }
    .mainCotizar-container-main {
        display: flex;
        flex-direction: column;
        margin-top: -100px;
        margin-left: auto;
        margin-right: auto;
        width: 95%;
        justify-content: center;
        align-items: center;
    }
        @media only screen and (max-width: 37.5em) {
            .mainCotizar-container-main {
                width: 90%;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                padding-bottom: 60px;
            }}
        .mainCotizar-container-main-fixedPrice {
            display: none;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background: var(--white);
            position: fixed;
            bottom: 0;
            z-index: 10;
            width: 100%;
            padding: 10px 0;
        }
            @media only screen and (max-width: 37.5em) {
                .mainCotizar-container-main-fixedPrice {
                    display: flex;
                }}
            .mainCotizar-container-main-fixedPrice-text {
                font-style: normal;
                font-weight: bold;
                font-size: 1.1875rem;
                line-height: 29px;
                color: var(--primary500);
                margin: 0;  
                margin-left: 20px;           
            }
            .mainCotizar-container-main-fixedPrice-price {
                font-style: normal;
                font-weight: bold;
                font-size: 1.5625rem;
                line-height: 42px;
                color: var(--primary500);
                margin: 0;
                margin-right: 20px;
            }
        .mainCotizar-container-main-days {
            display: flex;
            flex-direction: row;
            background: var(--white);
            border-radius: 25px;
            width: 25%;
        }
            @media only screen and (max-width: 37.5em) {
                .mainCotizar-container-main-days {
                    width: 100%;
                }}
            .mainCotizar-container-main-days-text {
                font-style: normal;
                font-weight: normal;
                font-size: 1rem;
                line-height: 19px;
                display: flex;
                align-items: center;
                color: var(--primary500);
                width: 85%;
                padding-left: 20px;
            }
            .mainCotizar-container-main-days-calendar {
                display: flex;
                align-items: center;
                justify-content: center;
                background: var(--primary500);
                border-radius: 0px 25px 25px 0px;
                width: 15%;
                cursor: pointer;
            }
        .mainCotizar-container-main-adicionalesContainer {
            display: flex;
            flex-direction: column;
        }
            @media only screen and (max-width: 37.5em) {
                .mainCotizar-container-main-adicionalesContainer {
                    align-items: center;
                    justify-content: center;
                }}
            .mainCotizar-container-main-adicionalesTitulo {
                font-style: normal;
                font-weight: bold;
                font-size: 1.125rem;
                line-height: 32px;
                color: var(--primary500);
                margin-left: 20px;
            }
            @media only screen and (max-width: 37.5em) {
                .mainCotizar-container-main-adicionalesTitulo {
                    text-align: center;
                    margin-left: 0;
                }}
            .mainCotizar-container-main-adicionales {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-left: 20px;
                flex-wrap: wrap;
            }
                @media only screen and (max-width: 37.5em) {
                    .mainCotizar-container-main-adicionales {
                        flex-direction: column;
                        margin-left: 0;
                        flex-wrap: nowrap;
                    }}
        .mainCotizar-container-main-titleText {
            font-style: normal;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 29px;
            text-align: center;
            color: var(--black);
        }
            @media only screen and (max-width: 37.5em) {
                .mainCotizar-container-main-titleText {
                    font-size: 0.875rem;
                    text-align: start;
                    align-self: flex-start;
                }}
        .mainCotizar-container-main-endButtons {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 20px;
            margin-top: 20px;
        }
            @media only screen and (max-width: 37.5em) {
                .mainCotizar-container-main-endButtons {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }}
            .mainCotizar-container-main-endButtons-firstButton {
                width: 20%;
            }
            @media only screen and (max-width: 37.5em) {
                .mainCotizar-container-main-endButtons-firstButton {
                    width: 80%;
                }}
            .mainCotizar-container-main-endButtons-secondButton {
                margin-left: 20px;
                width: 20%;
            }
            @media only screen and (max-width: 37.5em) {
                .mainCotizar-container-main-endButtons-secondButton {
                    width: 80%;
                    margin-top: 10px;
                    margin-left: 0;
                }
                .title-price-main{
                    font-style: normal;
                    font-weight:bold;
                    font-size: 26px;
                    line-height: 30px;
                    display: flex;
                    align-items: center;
                    text-align: right;
                    color: var(--fifthTextColor);
                    padding-left: 1rem;
                }
                .title-time-main{
                    font-style: normal;
                    font-weight: bold;
                    margin-left: 5px;
                    font-size: 8px;
                    line-height: 14px;
                    display: flex;
                    align-items: center;
                    color: var(--fifthTextColor);

                }
                .cotizar-otro-producto{
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: #888888;
                    opacity: 0.99;
                    cursor: pointer;
                    justify-content: center;
                }
            }
        .mainCotizar-container-main-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
            .mainCotizar-container-main-top-product {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                position: absolute;
                left: 50px;
                margin-top: 100px;
            }
                @media only screen and (max-width: 37.5em) {
                    .mainCotizar-container-main-top-product {
                        display: none;
                    }}
                .mainCotizar-container-main-top-product-marca {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 1.0625rem;
                    color: var(--black);
                    margin: 0;
                }
                .mainCotizar-container-main-top-product-title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.375rem;
                    color: var(--black);
                    margin: 10px 0;
                }
                .mainCotizar-container-main-top-product-modelo {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 0.9375rem;
                    color: var(--black);
                    margin: 0;
                    margin-bottom: 20px;
                }
                .mainCotizar-container-main-top-product-valor {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.75rem;
                    color: var(--black);
                    margin: 0;
                    margin-bottom: 10px;
                }
                .mainCotizar-container-main-top-product-valorValue {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.9375rem;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    color: var(--black);     
                    margin: 0;
                    background: #EEEEEE;
                    opacity: 0.5;
                    border-radius: 25px;
                    padding: 10px 10px;
                    width: fit-content;
                }
            .mainCotizar-container-main-top-periods {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;
                justify-content: center;
            }
                @media only screen and (max-width: 37.5em) {
                    .mainCotizar-container-main-top-periods {
                        width: 100%;
                    }}
    .mainCotizar-container-coberturaList {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }
        @media only screen and (max-width: 37.5em) {
            .mainCotizar-container-coberturaList {
                flex-wrap: nowrap;
                justify-content: flex-start;
                overflow-x: scroll;
                padding-bottom: 10px;
                width: 110%;
            }}
        .mainCotizar-container-coberturaList-cobertura {
            display: flex;
            flex-direction: column;
            background: var(--white);
            border: 1px solid var(--gray100);
            box-sizing: border-box;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 0px 50px 50px 50px;
            max-width: 475px;
            margin: 0 10px;
            margin-bottom: 10px;
        }
            @media only screen and (max-width: 37.5em) {
                .mainCotizar-container-coberturaList-cobertura {
                    min-width: 85% !important;
                }}
            .mainCotizar-container-coberturaList-cobertura-coverageGroups {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
            }
                .mainCotizar-container-coberturaList-cobertura-coverageGroups-estrellas {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                }
                .mainCotizar-container-coberturaList-cobertura-coverageGroups-name {
                    font-style: italic;
                    font-weight: 900;
                    font-size: 25px;
                    line-height: 25px;
                    text-align: center;
                    color: var(--primary500);
                }
        .mainCotizar-container-coberturaList-endButtons {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 20px;
            margin-top: 20px;
        }
            .mainCotizar-container-coberturaList-endButtons-firstButton {
                display: flex;
                width: 90%;
            }
            .mainCotizar-container-coberturaList-endButtons-secondButton {
                display: flex;
                width: 90%;
                margin-left: 20px;
                margin-top: 10px;
            }
                @media only screen and (max-width: 37.5em) {
                    .mainCotizar-container-coberturaList-endButtons-secondButton {
                        margin-left: 0;
                        margin-top: 10px;
                    }}
    .mainCotizar-container-cobertura {
        display: flex;
        flex-direction: column;
        background: var(--white);
        border: 1px solid var(--gray100);
        box-sizing: border-box;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 0px 50px 50px 50px;
        width: 100%;
    }
        .mainCotizar-container-cobertura-disclaimer {
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            text-align: center;
            color: var(--gray300);
        }
        @media only screen and (max-width: 37.5em) {
            .mainCotizar-container-cobertura-disclaimer {
                display: none;
            }}
        .mainCotizar-container-cobertura-coverageGroups {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
            @media only screen and (max-width: 37.5em) {
                .mainCotizar-container-cobertura-coverageGroups {
                    flex-direction: column;
                }}
            .mainCotizar-container-cobertura-coverageGroups-line {
                display: flex;
                width: 100%;
                height: 1px;
                background-color: var(--primaryThirdBackgroundColor);
            }
            .mainCotizar-container-cobertura-coverageGroups-coverages {
                display: flex;
                flex-direction: column;
                align-items: center; 
                justify-content: center;
                padding: 20px 0;
                width: 100%;
            }
                @media only screen and (max-width: 37.5em) {
                    .mainCotizar-container-cobertura-coverageGroups-coverages {
                        width: 100%;
                    }}
                .mainCotizar-container-cobertura-coverageGroups-coverages-form {
                    width: 50%;
                }
                    @media only screen and (max-width: 37.5em) {
                        .mainCotizar-container-cobertura-coverageGroups-coverages-form {
                            width: 80%;
                        }}
                .mainCotizar-container-cobertura-coverageGroups-coverages-insurerImg {
                    width: 60px;
                    margin: 0;
                    padding: 0;
                }
                .mainCotizar-container-cobertura-coverageGroups-coverages-title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.125rem;
                    line-height: 32px;
                    display: flex;
                    align-items: flex-end;
                    text-align: center;
                    color: var(--black);
                    margin: 0;
                    margin-bottom: 20px;
                }
                    @media only screen and (max-width: 37.5em) {
                        .mainCotizar-container-cobertura-coverageGroups-coverages-title {
                            font-size: 0.875rem;
                            margin-bottom: 0;
                            margin-top: -15px;
                        }}
                .mainCotizar-container-cobertura-coverageGroups-coverages-description {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.75rem;
                    line-height: 32px;
                    display: flex;
                    align-items: flex-end;
                    text-align: center;
                    color: var(--primary500);
                    margin: 0;
                }
                .mainCotizar-container-cobertura-coverageGroups-coverages-duracionText {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1rem;
                    line-height: 32px;
                    display: flex;
                    align-items: flex-end;
                    text-align: center;
                    color: var(--primary500);
                    margin: 0;
                }
                .mainCotizar-container-cobertura-coverageGroups-coverages-estrellas {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 20px;
                }
                .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }
                    .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                        .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-container-discountContainer {
                            display: flex;
                            flex-direction: row;
                            margin-bottom: -20px;
                            margin-top: 20px;
                            align-items: flex-end;
                            justify-content: center;
                        }
                            .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-container-discountContainer-price {
                                font-style: normal;
                                font-weight: bold;
                                font-size: 1.25rem;
                                text-decoration-line: line-through;
                                color: var(--primary500);
                                margin: 0;
                                margin-right: 10px;
                            }
                            .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-container-discountContainer-description {
                                background-color: var(--seventhTextColor);
                                margin: 0;
                                border-radius: 5px 5px 0px 0px;
                                color: var(--white);
                                font-style: normal;
                                font-weight: normal;
                                font-size: 1rem;
                                text-align: center;
                                padding: 5px;
                                position: relative;
                                display: inline-block;
                                width: 50%;
                            }
                            .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-container-discountContainer-description .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-container-discountContainer-description-tooltipText {
                                visibility: hidden;
                                width: 180px;
                                background-color: #555;
                                color: #fff;
                                text-align: center;
                                padding: 5px 0;
                                border-radius: 6px;
                                
                                /* Position the tooltip text */
                                position: absolute;
                                z-index: 1;
                                bottom: 125%;
                                left: 50%;
                                margin-left: -60px;
                                
                                /* Fade in tooltip */
                                opacity: 0;
                                transition: opacity 0.3s;
                            }
                            .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-container-discountContainer-description .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-container-discountContainer-description-tooltipText::after {
                                content: "";
                                position: absolute;
                                top: 100%;
                                left: 50%;
                                margin-left: -5px;
                                border-width: 5px;
                                border-style: solid;
                                border-color: #555 transparent transparent transparent;
                            }
                            .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-container-discountContainer-description:hover .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-container-discountContainer-description-tooltipText {
                                visibility: visible;
                                opacity: 1;
                            }
                    .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-minus {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 30px;
                        width: 30px;
                        background: var(--primary500);
                        border-radius: 25px;
                        cursor: pointer;
                    }
                    .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-text {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 2.1875rem;
                        line-height: 20px;
                        text-align: center;
                        color: var(--accent500);
                        margin: 0 20px;
                    }
                        @media only screen and (max-width: 37.5em) {
                            .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-text {
                                font-size: 1.125rem;
                            }}
                    .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-line {
                        display: none;
                    }
                        @media only screen and (max-width: 37.5em) {
                            .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-line {
                                display: flex;
                                width: 100%;
                                height: 1px;
                                margin-top: 20px;
                                margin-bottom: -20px;
                                background-color: var(--primary500);
                            }}
                    .mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-plus {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 30px;
                        width: 30px;
                        background: var(--primary500);
                        border-radius: 25px;
                        cursor: pointer;
                    }