.rangeCalendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: scroll;
}
    .rangeCalendar-container-close {
        margin-left: auto;
        cursor: pointer;
    }
        @media only screen and (max-width: 37.5em) {
            .rangeCalendar-container-close {
                margin-right: 20px;
            }}
    .rangeCalendar-container-title {
        font-style: normal;
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 22px;
        text-align: center;
        color: var(--primary500);
    }
        @media only screen and (max-width: 37.5em) {
            .rangeCalendar-container-title {
                font-size: 0.875rem;
                margin: 0;
            }}
    .rangeCalendar-container-main {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
        @media only screen and (max-width: 37.5em) {
            .rangeCalendar-container-main {
                flex-direction: column;
            }}
        .rangeCalendar-container-main-comparisonResponsive {
            display: none;
        }
            @media only screen and (max-width: 37.5em) {
                .rangeCalendar-container-main-comparisonResponsive {
                    display: flex;
                    width: 90%;
                    flex-direction: column;
                }}
            .rangeCalendar-container-main-comparisonResponsive-dia {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
                .rangeCalendar-container-main-comparisonResponsive-dia-titleDia {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 0.875rem;
                    color: var(--primary500);
                    margin: 0;
                }
                .rangeCalendar-container-main-comparisonResponsive-dia-dataDia {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.125rem;
                    text-align: right;
                    color: var(--primary500);
                    margin: 0;
                }
            .rangeCalendar-container-main-comparisonResponsive-line {
                width: 100%;
                height: 1px;
                background-color: var(--gray200);
                margin: 10px 0;
            }
            .rangeCalendar-container-main-comparisonResponsive-mes {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
            }
                .rangeCalendar-container-main-comparisonResponsive-mes-titleMes {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 0.875rem;
                    color: var(--fifthTextColor);
                    margin: 0;
                }
                .rangeCalendar-container-main-comparisonResponsive-mes-dataMes {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.125rem;
                    text-align: right;
                    color: var(--fifthTextColor);
                    margin: 0;
                }
        .rangeCalendar-container-main-comparison {
            width: 100%;
        }
            @media only screen and (max-width: 37.5em) {
                .rangeCalendar-container-main-comparison {
                    display: none;
                }}
            .rangeCalendar-container-main-comparison-titleDia {
                font-style: normal;
                font-weight: bold;
                font-size: 1.125rem;
                line-height: 22px;
                color: var(--primary500);
                margin: 0;
            }
            .rangeCalendar-container-main-comparison-dataDia {
                font-style: normal;
                font-weight: bold;
                font-size: 2.1875rem;
                line-height: 42px;
                color: var(--primary500);
                margin: 0;
                margin-top: 10px;
                margin-bottom: 20px;
            }
            .rangeCalendar-container-main-comparison-titleMes {
                font-style: normal;
                font-weight: bold;
                font-size: 1.125rem;
                line-height: 22px;
                color: var(--fifthTextColor);
                margin: 0;
                margin-top: 20px;
                margin-bottom: 10px;
            }
            .rangeCalendar-container-main-comparison-dataMes {
                font-style: normal;
                font-weight: bold;
                font-size: 2.1875rem;
                line-height: 42px;
                color: var(--fifthTextColor);
                margin: 0;
            }
            .rangeCalendar-container-main-comparison-line {
                width: 100%;
                height: 1px;
                background-color: var(--gray200);
            }
    .rangeCalendar-container-firstButton {
        width: 50%;
    }
        @media only screen and (max-width: 37.5em) {
            .rangeCalendar-container-firstButton {
                width: 80%;
                height: 100%;
            }}
    .rangeCalendar-container-secondButton {
        width: 50%;
    }
        @media only screen and (max-width: 37.5em) {
            .rangeCalendar-container-secondButton {
                width: 80%;
            }}