.FormFieldPhoto-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    .FormFieldPhoto-container-description {
        font-style: normal;
        font-weight: normal;
        font-size: 1.25rem;
        color: #999999;
        margin: 0;
        margin-bottom: 10px;
        text-overflow: ellipsis;
    }
    .FormFieldPhoto-container-list {
        display: flex;
        flex-direction: column;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldPhoto-container-list {
                flex-direction: row;
                overflow: scroll;
                padding-bottom: 10px;
            }}