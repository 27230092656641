.SliderOffer-inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
    .SliderOffer-inputContainer-title {
        font-style: normal;
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 32px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        color: var(--black);
        margin: 0;
    }
        @media only screen and (max-width: 37.5em) {
            .SliderOffer-inputContainer-title {
                font-size: 0.875rem;
                margin-bottom: 0;
                margin-top: -15px;
            }}
    .SliderOffer-inputContainer-sliderContainer {
        width: 100%;
        margin-top: 30px;
    }
        .SliderOffer-inputContainer-sliderContainer-minMaxContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
        }
            .SliderOffer-inputContainer-sliderContainer-minMaxContainer-text {
                font-style: normal;
                font-weight: bold;
                font-size: 1rem;
                line-height: 20px;
                text-align: center;
                color: var(--accent500);
                margin: 0;
            }