.FormFieldList-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    height: 100%;
    min-height: 500px;
}
    .FormFieldList-container-empty {
        display: flex;
        flex-direction: column;
    }
        .FormFieldList-container-empty-mask {
            display: flex;
            flex-direction: column;
            width: fit-content;
            margin-top: 30px;
            margin-left: 80px;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldList-container-empty-mask {
                    margin: 20px 0;
            }}
            .FormFieldList-container-empty-mask-svg {
                text-align: center;
            }
            .FormFieldList-container-empty-mask-title {
                font-style: normal;
                font-weight: 325;
                font-size: 1.5rem;
                text-align: center;
                color: var(--black);
                margin: 10px 0;
            }
            .FormFieldList-container-empty-mask-text {
                font-style: normal;
                font-weight: 325;
                font-size: 1rem;
                text-align: center;
                color: var(--black);
                margin: 0;
            }
    .FormFieldList-container-buscador {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 40%;
        background-color: var(--white);
        border: 2px solid var(--gray200);
        border-radius: 25px;
        padding: 10px;
        margin-bottom: 20px;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldList-container-buscador {
                width: 82.5%;
                margin-left: 20px;
        }}
    .FormFieldList-container-buscadorActive {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 40%;
        background-color: var(--white);
        border: 2px solid var(--primary500);
        border-radius: 25px;
        padding: 10px;
        margin-bottom: 20px;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldList-container-buscadorActive {
                width: 82.5%;
                margin-left: 20px;
        }}
        .FormFieldList-container-buscador-input {
            outline: none;
            border: none;
            width: 90%;
        }
        .FormFieldList-container-buscador-input::placeholder {
            color: var(--gray400);
        }   
    .FormFieldList-container-populares {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldList-container-populares {
                width: 90%;
                margin-left: 20px;
        }}
        .FormFieldList-container-populares-title {
            font-style: normal;
            font-weight: 325;
            font-size: 0.8rem;
            color: #0075BC;
            margin: 0;
            margin-bottom: 10px;
        }
        .FormFieldList-container-populares-list {
            width: 100%;
            list-style-type: disc;
            columns: 2;
            list-style-position: inside;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldList-container-populares-list {
                    -webkit-columns: 1;
                    -moz-columns: 1;
                    columns: 1;
            }}
            .FormFieldList-container-populares-list-item {
                font-style: normal;
                font-weight: 325;
                font-size: 1rem;
                color: var(--black);            
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-bottom-color: #C3D8F0;
                margin: 0;
                padding-top: 6px;
                padding-bottom: 5px;
                padding-left: 9px;
                width: 95%;
                cursor: pointer;
                -webkit-touch-callout: none; 
                -webkit-user-select: none; 
                -khtml-user-select: none; 
                -moz-user-select: none; 
                -ms-user-select: none; 
                user-select: none; 
            }
            .FormFieldList-container-populares-list-item:hover {
                background: var(--primary200);
                border-bottom-color: var(--primary200);
            }
            .FormFieldList-container-populares-list-item:active {
                background: var(--primary500);
                border-bottom-color: var(--primary500);
                color: var(--white);
            }
    .FormFieldList-container-values {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldList-container-values {
                width: 90%;
                margin-left: 20px;
                margin-top: 30px;
        }}
        .FormFieldList-container-values-title {
            font-style: normal;
            font-weight: 325;
            font-size: 0.8rem;
            color: #0075BC;
            margin: 0;
            margin-bottom: 10px;
        }
        .FormFieldList-container-values-list {
            width: 100%;
            list-style-type: disc;
            columns: 2;
            list-style-position: inside;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldList-container-values-list {
                    -webkit-columns: 1;
                    -moz-columns: 1;
                    columns: 1;
            }}
            .FormFieldList-container-values-list-item {
                font-style: normal;
                font-weight: 325;
                font-size: 1rem;
                color: var(--black);
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-bottom-color: #C3D8F0;
                margin: 0;
                padding-top: 6px;
                padding-bottom: 5px;
                padding-left: 9px;
                width: 95%;
                cursor: pointer;
                -webkit-touch-callout: none; 
                -webkit-user-select: none; 
                -khtml-user-select: none; 
                -moz-user-select: none; 
                -ms-user-select: none; 
                user-select: none; 
            }
            .FormFieldList-container-values-list-item:hover {
                background: var(--primary200);
                border-bottom-color: var(--primary200);
            }
            .FormFieldList-container-values-list-item:active {
                background: var(--primary500);
                border-bottom-color: var(--primary500);
                color: var(--white);
            }