.insurer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background: #FFFFFF;
    box-shadow: 0px 20px 35px -12px rgba(0, 0, 0, 0.2);
    border-radius: 15px;   
    width: 156px;
    cursor: pointer;
}
    .insurer-container-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 90px;
    }
        .insurer-container-top-star {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2.5px 7.5px;
            align-self: flex-end;
            background: var(--gray100);        
            border-radius: 10px;
            margin-right: 5px;
            margin-top: -5px;
        }
        .insurer-container-top-img {
            width: 60%;
        }
        .insurer-container-top-title {
            font-style: normal;
            font-weight: 325;
            font-size: 1rem;
            text-align: center;
            color: var(--primary500);     
            margin: 0;
            width: 50%;
            margin-top: -12.5px;
        }
    .insurer-container-bot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 40%;
        border-radius: 0 0 15px 15px;
    }
        .insurer-container-bot-value {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
            .insurer-container-bot-value-desde {
                font-style: normal;
                font-weight: 325;
                font-size: 0.7rem;            
                margin: 0;
            }
            .insurer-container-bot-value-value {
                font-style: normal;
                font-weight: 325;
                font-size: 1.1rem;
                margin: 0;
            }
        .insurer-container-bot-count {
            font-style: normal;
            font-weight: 325;
            font-size: 0.9rem;
            margin: 0;
        }