.titleTextAlert-container {
    display: flex;
    flex-direction: column;
}
    .titleTextAlert-container-close {
        margin-left: auto;
    }
    .titleTextAlert-container-title {
        font-style: normal;
        font-weight: 700;
        font-size: 1.5625rem;
        line-height: 20px;
        text-align: center;
        color: var(--primary500);
        margin: 0;
        margin-bottom: 20px;
    }
    .titleTextAlert-container-text {
        display: flex;
        flex-direction: column;
        max-height: 400px;
        width: 100%;
        overflow-y: scroll;
        margin-bottom: 20px;
        text-align: center;
    }
        @media only screen and (max-width: 37.5em) {
            .titleTextAlert-container-text {
                max-height: 300px;
            }
            @media only screen and (max-height: 700px) {
                .titleTextAlert-container-text {
                    max-height: 200px;
                }
            }
        }
    .titleTextAlert-container-buttonWrappers {
        display: flex;
        flex-direction: column;
        width: 60%; 
        margin-left: auto;
        margin-right: auto;
    }
        @media only screen and (max-width: 37.5em) {
            .titleTextAlert-container-buttonWrappers {
                width: 100%;
            }}