.offerPlan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    border: 3px solid var(--primary200);
    background: var(--white);
    min-width: 320px;
}
    .offerPlan-container-title {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px 25px 0 0;
        width: 100%;
        padding: 10px 0;
    }
        .offerPlan-container-title-text {
            color: var(--gray600);
        }
    .offerPlan-container-score {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 8px;
    }
    .offerPlan-container-planType {
        color: var(--gray400);
    }
    .offerPlan-container-price {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
    }
        .offerPlan-container-price-unit {
            color: var(--gray400);
            margin-bottom: 7px;
        }
        .offerPlan-container-price-price {
            color: var(--primary500);
        }
        .offerPlan-container-price-per {
            color: var(--gray400);
            margin-bottom: 9px;
        }
    .offerPlan-container-line {
        width: 100%;
        height: 1px;
        background-color: var(--gray200);
        margin: 16px 0;
    }
    .offerPlan-container-queCubre {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
    }
        .offerPlan-container-queCubre-title {
            color: var(--gray400);
            margin-bottom: 12px;
        }
        .offerPlan-container-queCubre-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 90%;
        }
            .offerPlan-container-queCubre-list-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: 12px;
            }
                .offerPlan-container-queCubre-list-item-title {
                    color: var(--gray600);
                    text-align: center;
                }
                .offerPlan-container-queCubre-list-item-descripcion {
                    color: var(--primary500);
                    text-align: center;
                }
    .offerPlan-container-boton {
        width: 90%;
        margin: 0px auto;
        margin-bottom: 10px;
    }