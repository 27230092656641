.offerPackage-container {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    max-width: 322px;
    min-width: 270px;
    width: 100%;
    margin: 10px;
}
    @media only screen and (max-width: 37.5em) {
        .offerPackage-container {
            min-width: 322px;
        }}
    .offerPackage-container-plan {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: center;
        border-radius: 35px 35px 0px 0px;
        height: 50px;
    }
        .offerPackage-container-plan-text {
            font-style: normal;
            font-weight: 325;
            font-size: 1.15rem;
            color: var(--primary500);
            margin: 0;
            margin-right: 10px;
            align-self: center;
        }
        .offerPackage-container-plan-img {
            width: 20%;
            height: auto
        }
    .offerPackage-container-stars {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
    }
    .offerPackage-container-pagasText {
        font-style: normal;
        font-weight: 325;
        font-size: 0.95rem;
        color: var(--secondary500);        
        margin: 0;
        margin-top: 10px;
    }
    .offerPackage-container-options {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
        .offerPackage-container-options-minus {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            width: 30px;
            background: var(--primary500);
            border-radius: 25px;
            cursor: pointer;
            text-decoration: none;
            border: none;
        }
        .offerPackage-container-options-text {
            font-style: normal;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 20px;
            text-align: center;
            color: var(--primary500);
            margin: 0 20px;
        }
            @media only screen and (max-width: 37.5em) {
                .offerPackage-container-options-text {
                    font-size: 1.125rem;
                }}
        .offerPackage-container-options-plus {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            width: 30px;
            background: var(--primary500);
            border-radius: 25px;
            cursor: pointer;
            text-decoration: none;
            border: none;
        }
    .offerPackage-container-queEsText {
        font-style: normal;
        font-weight: 325;
        font-size: 0.85rem;
        text-align: center;
        color: var(--primary500);
        margin: 0;
        margin-top: 5px;
        margin-bottom: 20px;
        cursor: pointer;
    }
    .offerPackage-container-value {
        font-style: normal;
        font-weight: 325;
        font-size: 2rem;
        color: var(--secondary500);        
        margin: 0;
        margin-bottom: 10px;
    }
    .offerPackage-container-line {
        height: 1px;
        width: 100%;
        background-color: #C4C4C4;
    }
    .offerPackage-container-dataCoverage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }
        .offerPackage-container-dataCoverage-label {
            font-style: normal;
            font-weight: 325;
            font-size: 0.8rem;
            color: #898D8D;
            margin: 0;
        }
        .offerPackage-container-dataCoverage-valueByLabel {
            font-style: normal;
            font-weight: 325;
            font-size: 1.2rem;
            color: var(--secondary500);
            margin: 10px 0;
        }
        .offerPackage-container-dataCoverage-vigencia {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background: var(--secondary200);
            border: 1px solid var(--secondary500);
            border-radius: 5px;
            padding: 5px 10px;
            margin: 10px 0;
            cursor: pointer;
        }
            .offerPackage-container-dataCoverage-vigencia-text {
                font-style: normal;
                font-weight: 325;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                margin: 0;
                color: var(--secondary500);
                margin-right: 5px;
            }
    .offerPackage-container-firstButton {
        width: 90%;
        margin-bottom: 15px;
    }
    .offerPackage-container-secondButton {
        width: 90%;
        margin-bottom: 20px;
    }
