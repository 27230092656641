.reclamo-containerFinal {
    background-color: var(--primary500);
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    overflow-y: scroll;
}
    .reclamo-containerFinal-finalMain {
        display: flex;
        flex-direction: column;
        background-color: var(--primary500);
        width: 40%;
        min-width: 420px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
    }
        @media only screen and (max-width: 37.5em) {
            .reclamo-containerFinal-finalMain {
                width: 100%;
                min-width: unset;
        }}
        .reclamo-containerFinal-finalMain-ResContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: var(--white);
            box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 50px 50px 0px 0px;
            position: fixed;
            bottom: 0;
            width: 100%;
            padding-top: 40px;
        }
        .reclamo-containerFinal-finalMain-text1 {
            font-style: normal;
            font-weight: 300;
            font-size: 2rem;
            text-align: center;
            color: var(--white);
            margin: 0;
        }
            @media only screen and (max-width: 37.5em) {
                .reclamo-containerFinal-finalMain-text1 {
                    font-size: 1.6rem;
            }}
        .reclamo-containerFinal-finalMain-text2 {
            font-style: normal;
            font-weight: 300;
            font-size: 1.9rem;
            text-align: center;
            color: var(--white);
            margin: 0;
            margin-top: 20px;
            margin-bottom: 40px;
        }
            @media only screen and (max-width: 37.5em) {
                .reclamo-containerFinal-finalMain-text2 {
                    font-size: 1.5rem;
            }}
        .reclamo-containerFinal-finalMain-text3 {
            font-style: normal;
            font-weight: 300;
            font-size: 1.5rem;
            text-align: center;
            color: var(--white);
            margin: 0;
        }
            @media only screen and (max-width: 37.5em) {
                .reclamo-containerFinal-finalMain-text3 {
                    font-size: 1.25rem;
                    color: #333333;
                    width: 90%;
            }}
        .reclamo-containerFinal-finalMain-text4 {
            font-style: normal;
            font-weight: 300;
            font-size: 1rem;
            text-align: center;
            color: var(--white);
            margin: 0;
            margin-top: 20px;
            margin-bottom: 30px;
        }
            @media only screen and (max-width: 37.5em) {
                .reclamo-containerFinal-finalMain-text4 {
                    font-size: 1rem;
                    color: #333333;
                    width: 90%;
            }}
        .reclamo-containerFinal-finalMain-buttonWrapper {
            width: 70%;
            margin-bottom: 10px;
        }
            @media only screen and (max-width: 37.5em) {
                .reclamo-containerFinal-finalMain-buttonWrapper {
                    width: 90%;
            }}
.reclamo-container {

}
    .reclamo-container-topContainer {
        display: flex;
        background-color: var(--primary500);
        border-bottom-left-radius: 50px;
        padding-top: 30px;
        padding-bottom: 60px;
        z-index: 10;
        position: relative;
        justify-content: space-between;
    }
        @media only screen and (max-width: 37.5em) {
            .reclamo-container-topContainer {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: var(--white);
                padding-bottom: 0;
            }}
        .reclamo-container-topContainer-text {
            font-style: normal;
            font-weight: normal;
            font-size: 3rem;
            color: var(--white);
            margin: 0;   
            margin-left: 50px; 
        }
            @media only screen and (max-width: 37.5em) {
                .reclamo-container-topContainer-text {
                    font-size: 1.75rem;
                    color: var(--primary500);
                    margin-left: 0; 
                    margin-bottom: 20px;
            }}
        .reclamo-container-topContainer-stepContainer {
            width: 40%;
            margin-right: 50px;
        }
            @media only screen and (max-width: 37.5em) {
                .reclamo-container-topContainer-stepContainer {
                    width: 95%;
                    margin-right: 0;
            }}
    .reclamo-container-mask {
        display: flex;
        background: var(--primary500);
        height: 100px; 
        width: 100%;
    }
        @media only screen and (max-width: 37.5em) {
            .reclamo-container-mask {
                display: none;
            }}
        .reclamo-container-mask-inner {
            display: flex;
            background: var(--white);
            height: 100px;
            width: 100%;
            border-top-right-radius: 50px;
        }
    .reclamo-container-main {
        margin-top: -70px;
    }
        @media only screen and (max-width: 37.5em) {
            .reclamo-container-main {
                margin-top: 0;
            }}
        .reclamo-container-main-title {
            font-style: normal;
            font-weight: bold;
            font-size: 1.5rem;
            color: #333333;  
            margin: 0;
            margin-left: 50px;
        }
            @media only screen and (max-width: 37.5em) {
                .reclamo-container-main-title {
                    width: 90%;
                    text-align: center;
                    font-size: 1.4rem;
                    margin-left: auto;
                    margin-right: auto;
                }}
        .reclamo-container-main-description {
            font-style: normal;
            font-weight: normal;
            font-size: 1.3rem;
            color: #333333;  
            margin: 0;
            margin-left: 50px;
            margin-top: 10px;
            margin-bottom: 30px;
        }
            @media only screen and (max-width: 37.5em) {
                .reclamo-container-main-description {
                    width: 92.5%;
                    text-align: center;
                    font-size: 1.25rem;
                    margin-left: auto;
                    margin-right: auto;
                }}
        .reclamo-container-main-buttonWrapper {
            width: 30%;
            margin-left: auto;
            margin-right: 50px;
            z-index: 12312;
            position: relative;
            margin-top: 10px;
        }
            @media only screen and (max-width: 37.5em) {
                .reclamo-container-main-buttonWrapper {
                    width: 92.5%;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 10px;
                }}
        .reclamo-container-main-formContainer {
            display: flex;
            flex-direction: row;
            width: 93%;
            justify-content: flex-start;
            align-items: flex-start;
            margin-left: auto;
            margin-right: auto;
            flex-wrap: wrap;
        }
            @media only screen and (max-width: 37.5em) {
                .reclamo-container-main-formContainer {
                    flex-direction: column;
                    width: 92.5%;
                }}
            .reclamo-container-main-formContainer-itemForm {
                display: flex;
                width: 32.5%;
                margin-right: 5px;
            } 
                @media only screen and (max-width: 37.5em) {
                    .reclamo-container-main-formContainer-itemForm {
                        width: 100%;
                        margin: 10px 0;
                    }}
                    .reclamo-container-main-formContainer-itemForm h1 {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 1.25rem;
                        line-height: 24px;
                        color: var(--black);
                        margin: 0;
                }
    .reclamo-container-contacto {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        text-align: center;
        color: #333333;
        margin: 0;
        margin-top: 30px;
    }
        .reclamo-container-contacto a{
            text-decoration: none;
            color: var(--primary500)
        }