.soap-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}
    .soap-container-main {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
        .soap-container-main-top {
            width: 100%;
            background-image: url('../../../assets/images/banner_soap.png');
            background-repeat: no-repeat;
            background-size: cover;
            height: 253px;
        }
            @media only screen and (max-width: 37.5em) {
                .soap-container-main-top {
                    background-image: url('../../../assets/images/banner_soap_mobile.png');
                    height: 290px;
                }}
            .soap-container-main-top-title {
                font-style: normal;
                font-weight: 325;
                font-size: 2.5rem;
                color: var(--primary500);
                margin-top: 140px;
                margin-left: 55px;
            }
                @media only screen and (max-width: 37.5em) {
                    .soap-container-main-top-title {
                        display: flex;
                        font-size: 2rem;
                        color: var(--white);
                        width: max-content;
                        margin-left: auto;
                        margin-right: auto;
                    }}
        .soap-container-main-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            width: 90%;
        }
            .soap-container-main-content-title {
                font-style: normal;
                font-weight: 325;
                font-size: 1.75rem;
                color: var(--black);                
                margin: 10px 0;
                margin-top: 40px;
                text-align: center;
            }
                @media only screen and (max-width: 37.5em) {
                    .soap-container-main-content-title {
                        font-size: 1.25rem;
                    }}
            .soap-container-main-content-subTitle {
                font-style: normal;
                font-weight: 325;
                font-size: 1.25rem;
                color: var(--gray800);
                margin: 0;
                margin-bottom: 40px;
                text-align: center;
            }
                @media only screen and (max-width: 37.5em) {
                    .soap-container-main-content-subTitle {
                        font-size: 1rem;
                    }}
            .soap-container-main-content-list {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                flex-wrap: wrap;
                margin: 40px 0;
            }
                @media only screen and (max-width: 37.5em) {
                    .soap-container-main-content-list {
                        margin: 0;
                        margin-bottom: 20px;
                        margin-top: 0;
                    }}
                .soap-container-main-content-list-hdi {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 47.5%;
                    position: relative;
                }
                    @media only screen and (max-width: 37.5em) {
                        .soap-container-main-content-list-hdi {
                            width: 100%;
                        }}
                    .soap-container-main-content-list-hdi-frameImg {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                    .soap-container-main-content-list-hdi-main {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                        .soap-container-main-content-list-hdi-main-img {
                            width: 25%;
                            height: auto;
                            align-self: center;
                            margin-top: 50px;
                            margin-left: 50px;
                        }
                            @media only screen and (max-width: 37.5em) {
                                .soap-container-main-content-list-hdi-main-img {
                                    width: 25%;
                                    margin-top: 30px;
                                    margin-left: 30px;
                                }}
                        .soap-container-main-content-list-hdi-main-content {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 50%;
                            margin-top: 40px;
                            margin-left: auto;
                        }
                            .soap-container-main-content-list-hdi-main-content-value {
                                display: flex;
                                flex-direction: row;
                                align-items: flex-end;
                            }
                                .soap-container-main-content-list-hdi-main-content-value-text {
                                    font-style: normal;
                                    font-weight: 325;
                                    font-size: 1.2rem;
                                    color: var(--gray800);                                
                                    margin: 0;
                                    margin-right: 5px;
                                }
                                    @media only screen and (max-width: 37.5em) {
                                        .soap-container-main-content-list-hdi-main-content-value-text {
                                            font-size: 1rem;
                                        }}
                                .soap-container-main-content-list-hdi-main-content-value-number {
                                    font-style: normal;
                                    font-weight: 325;
                                    font-size: 2rem;
                                    color: #8A941E;    
                                    margin: 0;                            
                                }
                                    @media only screen and (max-width: 37.5em) {
                                        .soap-container-main-content-list-hdi-main-content-value-number {
                                            font-size: 1.2rem;
                                        }}
                            .soap-container-main-content-list-hdi-main-content-button {
                                background: #8A941E;
                                border-radius: 25px;
                                font-style: normal;
                                font-weight: 325;
                                font-size: 1.25rem;
                                text-align: center;
                                color: var(--white);
                                margin: 0;
                                margin-top: 10px;
                                height: 40px;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 50%;
                            }
                            .soap-container-main-content-list-hdi-main-content-button:active {
                                font-size: 1rem;
                            }
                                @media only screen and (max-width: 37.5em) {
                                    .soap-container-main-content-list-hdi-main-content-button {
                                        padding: 10px 20px;
                                        font-size: 1rem;
                                        height: 20px;
                                    }
                                    .soap-container-main-content-list-hdi-main-content-button:active {
                                        font-size: 0.9rem;
                                    }}
                .soap-container-main-content-list-bci {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 47.5%;
                    position: relative;
                }
                    @media only screen and (max-width: 37.5em) {
                        .soap-container-main-content-list-bci {
                            width: 100%;
                            margin-top: 20px;
                        }}
                    .soap-container-main-content-list-bci-frameImg {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                    .soap-container-main-content-list-bci-main {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                        .soap-container-main-content-list-bci-main-img {
                            width: 25%;
                            height: auto;
                            align-self: center;
                            margin-top: 50px;
                            margin-left: 50px;
                        }
                            @media only screen and (max-width: 37.5em) {
                                .soap-container-main-content-list-bci-main-img {
                                    width: 25%;
                                    margin-top: 30px;
                                    margin-left: 30px;
                                }}
                        .soap-container-main-content-list-bci-main-content {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 50%;
                            margin-top: 40px;
                            margin-left: auto;
                        }
                            .soap-container-main-content-list-bci-main-content-value {
                                display: flex;
                                flex-direction: row;
                                align-items: flex-end;
                            }
                                .soap-container-main-content-list-bci-main-content-value-text {
                                    font-style: normal;
                                    font-weight: 325;
                                    font-size: 1.2rem;
                                    color: var(--gray800);                                
                                    margin: 0;
                                    margin-right: 5px;
                                }
                                    @media only screen and (max-width: 37.5em) {
                                        .soap-container-main-content-list-bci-main-content-value-text {
                                            font-size: 1rem;
                                        }}
                                .soap-container-main-content-list-bci-main-content-value-number {
                                    font-style: normal;
                                    font-weight: 325;
                                    font-size: 2rem;
                                    color: #0075BC;    
                                    margin: 0;                            
                                }
                                    @media only screen and (max-width: 37.5em) {
                                        .soap-container-main-content-list-bci-main-content-value-number {
                                            font-size: 1.2rem;
                                        }}
                                .soap-container-main-content-list-bci-main-content-button {
                                    background: #0075BC;
                                    border-radius: 25px;
                                    font-style: normal;
                                    font-weight: 325;
                                    font-size: 1.25rem;
                                    text-align: center;
                                    color: var(--white);
                                    margin: 0;
                                    margin-top: 10px;
                                    height: 40px;
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 50%;
                                }
                                    @media only screen and (max-width: 37.5em) {
                                        .soap-container-main-content-list-bci-main-content-button {
                                            padding: 10px 20px;
                                            font-size: 1rem;
                                            height: 20px;
                                        }
                                        .soap-container-main-content-list-bci-main-content-button:active {
                                            font-size: 0.9rem;
                                        }}
            .soap-container-main-content-buttonWrapper {
                width: 20%;
                margin: 30px 0;
                margin-right: auto;
            }
                @media only screen and (max-width: 37.5em) {
                    .soap-container-main-content-buttonWrapper {
                        width: 100%;
                    }}