.motoExito-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}
    .motoExito-container-main {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
        .motoExito-container-main-top {
            width: 100%;
            background-image: url('../../../assets/images/banner_moto.png');
            background-repeat: no-repeat;
            background-size: cover;
            height: 253px;
        }
            .motoExito-container-main-top-title {
                font-style: normal;
                font-weight: 325;
                font-size: 2.5rem;
                color: var(--primary500);
                margin-top: 140px;
                margin-left: 55px;
            }
                @media only screen and (max-width: 37.5em) {
                    .motoExito-container-main-top-title {
                        display: flex;
                        font-size: 2rem;
                        color: var(--white);
                        width: max-content;
                        margin-left: auto;
                        margin-right: auto;
                    }}
        .motoExito-container-main-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
            .motoExito-container-main-content-title {
                font-style: normal;
                font-weight: 325;
                font-size: 2rem;
                text-align: center;
                color: var(--primary500);     
                margin: 0;
                margin-top: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .motoExito-container-main-content-title {
                        width: 90%;
                        font-size: 1.9rem;
                    }}
            .motoExito-container-main-content-subTitle {
                font-style: normal;
                font-weight: 325;
                font-size: 1.2rem;
                text-align: center;
                color: var(--black);
                margin: 0;
                margin-top: 20px;
            }
                @media only screen and (max-width: 37.5em) {
                    .motoExito-container-main-content-subTitle {
                        width: 90%;
                        font-size: 1.1rem;
                    }}
            .motoExito-container-main-content-buttonWrapper {
                width: 20%;
                margin-bottom: 100px;
                margin-top: 50px;
            }
                @media only screen and (max-width: 37.5em) {
                    .motoExito-container-main-content-buttonWrapper {
                        width: 90%;
                    }}