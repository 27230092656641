.offerSs-container {
    display: flex;
    flex-direction: column;  
    overflow-x: hidden;
    width: 100%;
}
    .offerSs-container-mainEmpty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 125px 0;
    }
        @media only screen and (max-width: 37.5em) {
            .offerSs-container-mainEmpty {
                margin: 75px 0;
            }}
        .offerSs-container-mainEmpty-title {
            font-style: normal;
            font-weight: 325;
            font-size: 1.5rem;
            text-align: center;
            color: var(--black);
        }
        .offerSs-container-mainEmpty-text {
            font-style: normal;
            font-weight: 325;
            font-size: 1.25rem;
            text-align: center;
            color: var(--gray300);
            margin-top: 0;
        }
        .offerSs-container-mainEmpty-buttonWrapper {
            width: 20%;
        }
        @media only screen and (max-width: 37.5em) {
            .offerSs-container-mainEmpty-buttonWrapper {
                width: 70%;
            }}
    .offerSs-container-mainLoading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 80%;
    }
        @media only screen and (max-width: 37.5em) {
              .offerSs-container-mainLoading {
                    height: 100%;
              }}
        .offerSs-container-mainLoading-lottieWrapper {
              height: 60%;
              width: 100%;
              margin-bottom: -120px;
        }
              @media only screen and (max-width: 37.5em) {
                    .offerSs-container-mainLoading-lottieWrapper {
                          margin: -20px 0;
                    }}
        .offerSs-container-mainLoading-text {
              font-style: normal;
              font-weight: 325;
              font-size: 2.75rem;
              text-align: center;
              color: var(--primary500);
              margin: 0;
              margin-bottom: 50px;
        }
        @media only screen and (max-width: 37.5em) {
              .offerSs-container-mainLoading-text {
                    font-size: 1rem;
                    width: 50%;
              }}
    .offerSs-container-top {
        width: 100%;
        background-image: url('../../../assets/images/banner_automovil.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 240px;
    }
        @media only screen and (max-width: 37.5em) {
            .offerSs-container-top {
                background-image: url('../../../assets/images/banner_automovil_mobile.png');
                height: 290px;
            }}
        @media only screen and (min-width: 2200px) {
            .offerSs-container-top {
                background-image: url('../../../assets/images/banner_automovil_uw.png');
            }}
        .offerSs-container-top-title {
            font-style: normal;
            font-weight: 325;
            font-size: 2.5rem;
            color: #0075BC;
            margin-top: 130px;
            margin-left: 55px;
        }
            @media only screen and (max-width: 37.5em) {
                .offerSs-container-top-title {
                    display: flex;
                    font-size: 2rem;
                    color: var(--white);
                    width: max-content;
                    margin-left: auto;
                    margin-right: auto;
                }}
    .offerSs-container-main {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 50px;
        margin-top: 20px;
        align-items: center;
        justify-content: center;
    }
        @media only screen and (max-width: 37.5em) {
            .offerSs-container-main {
                width: 100%;
            }}
        .offerSs-container-prices {
            display: flex;
            justify-content: center;
            width: 95%;
            margin: 0 auto;
            max-width: 1444px;
        }
        .offerSs-container-faq {
            width: 95%;
            max-width: 1444px;
            margin: 0 auto;
        }
        .offerSs-container-main-title {
            font-style: normal;
            font-weight: 325;
            font-size: 1rem;
            text-align: center;
            color: #000000;
            margin: 10px 0;
        }
            @media only screen and (max-width: 37.5em) {
                .offerSs-container-main-title {
                    width: 95%;
                    margin: 10px auto;
                }}
        .offerSs-container-main-buttons {
            display: flex;
            flex-direction: row;
            width: 95%;
            max-width: 1444px;
            align-items: flex-end;
            justify-content: flex-end;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
        }
            @media only screen and (max-width: 37.5em) {
                .offerSs-container-main-buttons {
                    flex-direction: column;
                    margin-right: auto;
                }}
            .offerSs-container-main-buttons-wrapper {
                width: 20%;
            }
                @media only screen and (max-width: 37.5em) {
                    .offerSs-container-main-buttons-wrapper {
                        width: 100%;
                    }}
        .offerSs-container-main-packageList {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: flex-start;
            justify-content: safe-center;
            margin-top: 10px;
            padding-bottom: 20px;
            overflow-x: auto;
        }
            @media only screen and (max-width: 37.5em) {
                .offerSs-container-main-packageList {
                    justify-content: flex-start;
                }}
        .offerSs-container-main-insurerList {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            padding-bottom: 40px;
            padding-top: 20px;
            max-width: 1444px;
        }
            @media only screen and (max-width: 37.5em) {
                .offerSs-container-main-insurerList {
                    overflow-x: scroll;
                    justify-content: flex-start;
                }}
            .offerSs-container-main-insurerList-item {
                margin: 0 15px;
            }