.imagePickerComponent-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--white);
    border: 0.5px solid var(--gray100);
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    height: 120px;
    width: 120px;
    margin: 0 10px;
}
    @media only screen and (max-width: 37.5em) {
        .imagePickerComponent-container {
            height: 90px;
            width: 90px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        }}
    .imagePickerComponent-container:active {
        transform: scale(0.9);
    }
    .imagePickerComponent-container-editButton {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--primary500);
        border-radius: 25px;
        height: 34px;
        width: 34px;
        position: absolute;
        margin-top: -100px;
        margin-left: 100px;
    }
        @media only screen and (max-width: 37.5em) {
            .imagePickerComponent-container-editButton {
                height: 24px;
                width: 24px;
                margin-top: -80px;
                margin-left: 80px;
            }}
    .imagePickerComponent-container-input {
        display: none;
    }
    .imagePickerComponent-container-img {
        height: 120px;
        width: 120px;
        border-radius: 25px;
    }
        @media only screen and (max-width: 37.5em) {
            .imagePickerComponent-container-img {
                height: 90px;
                width: 90px;
            }}