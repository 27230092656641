.homeSeguro-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 380px;
    height: 140px;
    border-radius: 15px;
    background: var(--white);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
}
    @media only screen and (max-width: 37.5em) {
        .homeSeguro-container {
            min-width: unset;
            min-width: 95%;
        }
    }
    .homeSeguro-container-main {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        height: 100%;
    }
        .homeSeguro-container-main-imgWrap {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 80%;
            width: 35%;
            border-radius: 8px;
            background: var(--primary200);
        }
            .homeSeguro-container-main-imgWrap-img {
                width: 80%;
                height: 80%;
            }
        .homeSeguro-container-main-data {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 100%;
            width: 60%;
            position: relative;
        }
            .homeSeguro-container-main-data-status {
                width: fit-content;
                padding: 4px 8px;
                border-radius: 0px 0px 8px 8px;
                background: var(--gray100);
                position: absolute;
                top: 0;
            }
            .homeSeguro-container-main-data-product {
                color: var(--gray700);
            }
            .homeSeguro-container-main-data-switch {
                display: flex;
                align-items: center;
                border-radius: 100px;
                min-width: 53px;
                height: 32px;
                position: absolute;
                top: 10px;
                right: 0;
                transition: all 0.5s ease-in;
                cursor: pointer;
            }
                .homeSeguro-container-main-data-switch-ball {
                    min-width: 24px;
                    height: 24px;
                    background-color: var(--white);
                    border-radius: 25px;
                    transition: all 0.5s ease-in;
                }
            .homeSeguro-container-main-data-ver {
                position: absolute;
                bottom: 10px;
                right: 0;
                color: var(--secondary500);
                cursor: pointer;
            }