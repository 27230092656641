.moto-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}
    .moto-container-main {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
        .moto-container-main-captcha {
            align-self: flex-end;
            margin-right: 20px;
            margin-top: 40px;
        }
            @media only screen and (max-width: 37.5em) {
                .moto-container-main-captcha {
                    align-self: center;
                    margin-right: 0;
                }}
        .moto-container-main-top {
            width: 100%;
            background-image: url('../../../assets/images/banner_moto.png');
            background-repeat: no-repeat;
            background-size: cover;
            height: 253px;
        }
            @media only screen and (max-width: 37.5em) {
                .moto-container-main-top {
                    background-image: url('../../../assets/images/banner_moto_mobile.png');
                    height: 290px;
                }}
            .moto-container-main-top-title {
                font-style: normal;
                font-weight: 325;
                font-size: 2.5rem;
                color: var(--primary500);
                margin-top: 140px;
                margin-left: 55px;
            }
                @media only screen and (max-width: 37.5em) {
                    .moto-container-main-top-title {
                        display: flex;
                        font-size: 2rem;
                        color: var(--white);
                        width: max-content;
                        margin-left: auto;
                        margin-right: auto;
                    }}
        .moto-container-main-title {
            font-style: normal;
            font-weight: 325;
            font-size: 1.25rem;
            color: var(--gray800); 
            margin: 0;
            margin-left: 35px;
            margin-top: 50px;
        }
            @media only screen and (max-width: 37.5em) {
                .moto-container-main-title {
                    text-align: center;
                    font-size: 1rem;
                    margin-left: 0;
                    margin-bottom: -40px;
                    width: 90%;
                    align-self: center;
                }}
        .moto-container-main-inputs {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            margin-top: 50px;
        }
            @media only screen and (max-width: 37.5em) {
                .moto-container-main-inputs {
                    flex-direction: column;
                    justify-content: center;
                    width: 90%;
                    margin: 0 auto;
                    margin-top: 50px;
                }}
            .moto-container-main-inputs-list {
                display: flex;
                flex-direction: column;
                width: 46.5%
            }
                @media only screen and (max-width: 37.5em) {
                    .moto-container-main-inputs-list {
                        width: 100%
                    }}
                .moto-container-main-inputs-list-title {
                    font-style: normal;
                    font-weight: 325;
                    font-size: 1.75rem;
                    color: var(--black);
                    margin: 0;
                }
                    @media only screen and (max-width: 37.5em) {
                        .moto-container-main-inputs-list-title {
                            font-size: 1.5rem;
                            margin-top: 40px;
                        }}
            .moto-container-main-inputs-list-inputWrapper {
                margin-left: -9px;
            }
        .moto-container-main-buttons {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            margin: 40px 0;
        }
            @media only screen and (max-width: 37.5em) {
                .moto-container-main-buttons {
                    flex-direction: column;
                }}
            .moto-container-main-buttons-buttonWrapper {
                width: 20%;
                margin: 0 10px;
            }
                @media only screen and (max-width: 37.5em) {
                    .moto-container-main-buttons-buttonWrapper {
                        width: 90%;
                        margin: 5px 0;
                    }}