.verificacionEmail-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0;
    bottom: 0;
    background-color: var(--primary500); 
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}
    .verificacionEmail-container-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
    }
        .verificacionEmail-container-main-successResendCodeContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background: #27AE60;
            width: 427px;
            height: 55px;
            position: absolute;
            right: 0;
            transition: all 2s ease;
        }
            .verificacionEmail-container-main-successResendCodeContainer-left {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: 20px;
            }
                .verificacionEmail-container-main-successResendCodeContainer-left-text {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 1rem;
                    line-height: 19px;
                    color: var(--white);
                    margin: 0;
                    margin-left: 10px;
                }
            .verificacionEmail-container-main-successResendCodeContainer-right {
                margin-right: 10px;
            }
        .verificacionEmail-container-main-title {
            font-style: normal;
            font-weight: normal;
            font-size: 2.1875rem;
            line-height: 32px;
            text-align: center;
            color: var(--white);
            width: 30%;
            margin: 0;
            margin-top: 40px;
            margin-bottom: 50px;
        }
            @media only screen and (max-width: 37.5em) {
                .verificacionEmail-container-main-title {
                    width: 80%;
                    font-size: 1.5625rem;
                    line-height: 25px;
                }}
        .verificacionEmail-container-main-subTitle {
            font-style: normal;
            font-weight: normal;
            font-size: 1.5rem;
            line-height: 29px;
            text-align: center;
            color: var(--white);
            width: 30%;     
            margin: 0;    
            margin-bottom: 45px;
        }
            @media only screen and (max-width: 37.5em) {
                .verificacionEmail-container-main-subTitle {
                    width: 80%;
                    font-size: 0.875rem;
                    line-height: 19px;
                }}
        .verificacionEmail-container-main-resendText {
            font-style: normal;
            font-weight: 300;
            font-size: 1.125rem;
            line-height: 24px;
            text-align: center;
            color: var(--white);
            margin: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .verificacionEmail-container-main-buttonWrapper {
            width: 30%;
            margin-bottom: 10px;
        }
        @media only screen and (max-width: 37.5em) {
            .verificacionEmail-container-main-buttonWrapper {
                width: 80%;
            }}
        .verificacionEmail-container-main-codeContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 40px;
        }
            .verificacionEmail-container-main-codeContainer-inputContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 60px;
                height: 75px;
                border: 2px solid var(--gray200);
                box-sizing: border-box;
                border-radius: 15px;
                margin: 0 10px;
            }
                @media only screen and (max-width: 37.5em) {
                    .verificacionEmail-container-main-codeContainer-inputContainer {
                        width: 40px;
                        height: 55px;
                    }}
                .verificacionEmail-container-main-codeContainer-inputContainer-input {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 2.1875rem;
                    text-align: center;
                    color: var(--white);
                    margin: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                    background-color: transparent;
                }
                    @media only screen and (max-width: 37.5em) {
                        .verificacionEmail-container-main-codeContainer-inputContainer-input {
                            font-size: 1.5625rem;
                        }}
                    .verificacionEmail-container-main-codeContainer-inputContainer-input:focus {
                        outline: none;
                    }