.adicionalComponent-container {
    display: flex;
    flex-direction: row;
    width: fit-content;
    border-radius: 25px;
    padding: 20px 20px;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-right: 10px;
    transition: all 0.6s ease;
}
    @media only screen and (max-width: 37.5em) {
        .adicionalComponent-container {
            width: 80%;
            margin-bottom: 10px;
            margin-right: 0;
        }}
    .adicionalComponent-container-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
        .adicionalComponent-container-left-switch {
            transition: all 0.6s ease;
            display: flex;
            width: 72px;
            height: 43px;
            box-sizing: border-box;
            border-radius: 100px;
            align-items: center;
            cursor: pointer;
            user-select: none;
            -o-user-select: none;
            -webkit-user-select: none; /* Chrome/Safari */ 
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+ */
        }
            @media only screen and (max-width: 37.5em) {
                .adicionalComponent-container-left-switch {
                    width: 62px;
                    height: 33px;
                }}
            .adicionalComponent-container-left-switch-circle {
                transition: all 0.6s ease;
                width: 34px;
                height: 34px;
                background: var(--white);
                border-radius: 25px;
                box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.05), 5px 0px 8px rgba(0, 0, 0, 0.05);
                user-select: none;
            }
                @media only screen and (max-width: 37.5em) {
                    .adicionalComponent-container-left-switch-circle {
                        width: 24px;
                        height: 24px;
                    }}
        .adicionalComponent-container-left-price {
            font-style: normal;
            font-weight: bold;
            font-size: 1.125rem;
            color: var(--primary500);
            margin: 0;
            margin-top: 10px;
        }
            @media only screen and (max-width: 37.5em) {
                .adicionalComponent-container-left-price {
                    font-size: 0.75rem;
                }}
    .adicionalComponent-container-right {
        margin-left: 10px;
    }
        .adicionalComponent-container-right-titulo {
            font-style: normal;
            font-weight: bold;
            font-size: 1rem;
            color: var(--primary500);
            margin: 0;
            margin-bottom: 10px;
        }
            @media only screen and (max-width: 37.5em) {
                .adicionalComponent-container-right-titulo {
                    font-size: 0.75rem;
                }}
        .adicionalComponent-container-right-description {
            font-style: normal;
            font-weight: normal;
            font-size: 1rem;
            letter-spacing: -0.035em;
            color: var(--primary500);
            margin: 0;
        }
            @media only screen and (max-width: 37.5em) {
                .adicionalComponent-container-right-description {
                    font-size: 0.75rem;
                }}