.reclamarPolizaAlert-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 100%;
    width: 100%;
  }
    .reclamarPolizaAlert-container-main {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
    }
    .reclamarPolizaAlert-container-title {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      text-align: center;
      margin: 0;
      margin-top: 30px;
      color: var(--primary500);
    }
    .reclamarPolizaAlert-container-text {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      margin: 20px 0;
    }
    .reclamarPolizaAlert-container-buttonWrapper {
      width: 30%;
      align-self: center;
    }