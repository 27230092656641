.container-card-sugeridos{
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0px 5px 0px 15px;
    position: relative;
}

.help-text-suggest{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000;
}

@media only screen and (max-width: 620px) {
    .container-card-sugeridos{
        margin-top: -80px;
    }
}