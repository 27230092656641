.reclamoSteps-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: space-evenly;
}
    .reclamoSteps-container-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
        .reclamoSteps-container-item-editText {
            font-style: normal;
            font-weight: bold;
            font-size: 0.75rem;
            color: var(--white); 
            margin: 0;
            margin-top: 5px;
            text-decoration: underline;
            cursor: pointer;
        }
        .reclamoSteps-container-item-data {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
        }
            .reclamoSteps-container-item-data-circle {
                display: flex;
                min-height: 40px;
                min-width: 40px;
                border-radius: 25px;
                background-color: #DEDEDE;
                align-items: center;
                justify-content: center;
            }
                @media only screen and (max-width: 37.5em) {
                    .reclamoSteps-container-item-data-circle {
                        height: 20px;
                        width: 20px;
                    }}
                .reclamoSteps-container-item-data-circle-check {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    padding: 0;
                }
            .reclamoSteps-container-item-data-line {
                display: flex;
                height: 1px;
                background-color: #DEDEDE;
                width: 100%;
            }
                @media only screen and (max-width: 37.5em) {
                    .reclamoSteps-container-item-data-line {
                        width: 80%;
                    }}
        .reclamoSteps-container-item-pasoText {
            font-style: normal;
            font-weight: bold;
            font-size: 0.625rem;
            color: var(--white); 
            margin: 0;
            margin-top: 10px;
            margin-bottom: 5px;
        }
        .reclamoSteps-container-item-nameText {
            font-style: normal;
            font-weight: bold;
            font-size: 0.875rem;
            color: var(--white); 
            margin: 0;
            width: 50%;
        }
            @media only screen and (max-width: 37.5em) {
                .reclamoSteps-container-item-nameText {
                    width: 70%;
                    font-size: 0.75rem;
                }}