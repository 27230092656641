.changePassword-container {

}
    .changePassword-container-success {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
        .changePassword-container-success-title {
            font-style: normal;
            font-weight: 300;
            font-size: 3rem;
            text-align: center;
            color: var(--primary500);
            margin: 0;
            margin-bottom: 50px;
        }
        @media only screen and (max-width: 37.5em) {
            .changePassword-container-success-title {
                font-size: 2rem;
            }
        }
        .changePassword-container-success-text {
            font-style: normal;
            font-weight: normal;
            font-size: 2rem;
            text-align: center;
            color: #333333; 
            margin: 0;    
            width: 40%;       
        }
        @media only screen and (max-width: 37.5em) {
            .changePassword-container-success-text {
                width: 100%;
                font-size: 1.5rem;
            }
        }
    .changePassword-container-top {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primary500);
        border-bottom-left-radius: 50px;
        padding-top: 30px;
        padding-bottom: 60px;
    }
        @media only screen and (max-width: 37.5em) {
            .changePassword-container-top {
                align-items: flex-start;
                justify-content: flex-start;
                background-color: transparent;
                padding: 0;
                padding-top: 10px;
            }
        }
        .changePassword-container-top-title {
            font-style: normal;
            font-weight: normal;
            font-size: 3rem;
            color: var(--white);
            margin: 0;
        }
            @media only screen and (max-width: 37.5em) {
                .changePassword-container-top-title {
                    font-size: 1.25rem;
                    color: #333333;
                    font-weight: bold;
                    margin-left: 18px;
                }
            }
    .changePassword-container-mask {
        display: flex;
        background: var(--primary500);
        height: 100px;
        width: 100%;
    }
        @media only screen and (max-width: 37.5em) {
            .changePassword-container-mask {
                display: none;
            }
        }
        .changePassword-container-mask-inner {
            display: flex;
            background: var(--gray50);
            height: 100px;
            width: 100%;
            border-top-right-radius: 50px;
        }
    .changePassword-container-main {
        display: flex;
        flex-direction: column;
        width: 420px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
        margin-top: 40px;
    }
        @media only screen and (max-width: 37.5em) {
            .changePassword-container-main {
                width: 90%;
                margin-top: 20px;
            }
        }
        .changePassword-container-main-noticeText {
            font-style: normal;
            font-weight: bold;
            font-size: 1rem;
            color: #999999;
            margin: 0;
            margin: 2px 0;
        }