.historyQuestion-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
}
    .historyQuestion-container-lineContainer {
        margin-right: 15px;
    }
    .historyQuestion-container-goBack {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        margin-bottom: 20px;
        align-items: center;
        cursor: pointer;
    }
        .historyQuestion-container-goBack-text {
            font-style: normal;
            font-weight: 325;
            font-size: 1rem;
            color: var(--primary500);
            margin: 0;
            margin-left: 10px;
        }
    .historyQuestion-container-prevLine {
        background: var(--primary500);
        width: 2px;
        min-height: 50px;
        margin-right: 13.5px;
    }
    .historyQuestion-container-endLine {
        background: #DEDEDE;
        width: 2px;
        height: 100%;
        margin-right: 17.5px;
    }
    .historyQuestion-container-question {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
        .historyQuestion-container-question-textContainer {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-right: 17.5px;
        }
            .historyQuestion-container-question-textContainer-text {
                font-style: normal;
                font-weight: bold;
                font-size: 0.875rem;
                line-height: 16px;
                display: flex;
                align-items: center;
                text-align: right;
                letter-spacing: 0.02em;
                color: var(--black);
                margin: 0;
            }
            .historyQuestion-container-question-textContainer-edit {
                display: flex;
                flex-direction: row;
                margin-top: 1px;
                cursor: pointer;
            }
                .historyQuestion-container-question-textContainer-edit-text {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 0.75rem;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    text-align: right;
                    color: var(--primary500);
                    margin: 0;
                    margin-left: 5px;
                }