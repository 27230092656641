.calendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    .calendar-container-close {
        margin-left: auto;
        cursor: pointer;
    }
    .calendar-container-titulo {
        font-style: normal;
        font-weight: 325;
        font-size: 20px;
        line-height: 24px;
        color: var(--primary500);
        margin: 0;
    }
    .calendar-container-button {
        width: 30%;
    }
        @media only screen and (max-width: 37.5em) {
            .calendar-container-button {
                width: 40%;
            }}