.perfilHome-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    @media only screen and (max-width: 37.5em) {
        .perfilHome-container {
            width: 95%;
            flex-direction: column;
        }}
    .perfilHome-container-main {
        display: flex;
        flex-direction: column;
        width: 94%;
        margin: 0 auto;
    }
        @media only screen and (max-width: 37.5em) {
            .perfilHome-container-main {
                width: 91%;
                margin-left: 25px;
            }}
        .perfilHome-container-main-superTitulo {
            margin: 30px 0;
        }
        .perfilHome-container-main-titulo {
            color: var(--gray700);
            margin-bottom: 4px;
        }
        .perfilHome-container-main-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 97.5%;
        }
            @media only screen and (max-width: 37.5em) {
                .perfilHome-container-main-list {
                    flex-direction: column;
                    align-items: center;
                    flex-wrap: nowrap;
                    width: 100%;
                }}
            .perfilHome-container-main-list-list {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 31%;
            }
                .perfilHome-container-main-list-list > * {
                    width: 31%;
                }
            .perfilHome-container-main-list-invis {
                width: 31%;
                margin-bottom: 30px;
            }
            .perfilHome-container-main-list > * {
                width: 31%;
                margin-bottom: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .perfilHome-container-main-list > * {
                        width: 100%;
                    }}
            .perfilHome-container-main-list-item-selectorWrapper {
                display: flex;
                flex-direction: column;
                width: 31%;
                margin-bottom: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .perfilHome-container-main-list-item-selectorWrapper {
                        width: 100%;
                    }}
                .perfilHome-container-main-list-item-selectorWrapper-title {
                    color: var(--tertiary500);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                }
                .perfilHome-container-main-list-item-selectorWrapper-title ul {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.25rem;
                    line-height: 24px;
                    color: red;
                    margin: 0;
                    padding: 0;
                }
            .perfilHome-container-main-list-innerList {
                display: flex;
                flex-direction: row;
                width: 103%;
            }
                @media only screen and (max-width: 37.5em) {
                    .perfilHome-container-main-list-innerList {
                        width: 105%;
                    }}