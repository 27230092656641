.periodsComponent-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background: #E8E8E8;
    border-radius: 25px;
}
    @media only screen and (max-width: 37.5em) {
        .periodsComponent-container {
            margin-bottom: 10px;
        }}
    .periodsComponent-container-item {
        display: flex;
        justify-content: center;
        border-radius: 25px;
        padding: 10px 0;
        width: 100%;
        cursor: pointer;
        transition: ease 0.5s all;
    }
        .periodsComponent-container-item-text {
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 32px;
            text-align: center;
            color: var(--white);
            margin: 0;
        }
        @media only screen and (max-width: 37.5em) {
            .periodsComponent-container-item-text {
                font-size: 0.75rem;
            }}
        .periodsComponent-container-item-discount {
            position: absolute;
            font-style: normal;
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 32px;
            text-align: center;
            color: var(--primary500);
            margin-top: 40px;
            margin-bottom: 20px;
        }
            @media only screen and (max-width: 37.5em) {
                .periodsComponent-container-item-discount {
                    font-size: 0.75rem;
                }}