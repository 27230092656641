.automovil-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}
    .automovil-container-main {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
        .automovil-container-main-captcha {
            align-self: flex-end;
            margin-right: 20px;
            margin-top: 40px;
        }
            @media only screen and (max-width: 37.5em) {
                .automovil-container-main-captcha {
                    align-self: center;
                    margin-right: 0;
                }}
        .automovil-container-main-top {
            width: 100%;
            background-image: url('../../../assets/images/banner_automovil.png');
            background-repeat: no-repeat;
            background-size: cover;
            height: 253px;
        }
            @media only screen and (max-width: 37.5em) {
                .automovil-container-main-top {
                    background-image: url('../../../assets/images/banner_automovil_mobile.png');
                    height: 290px;
                }}
            .automovil-container-main-top-title {
                font-style: normal;
                font-weight: 325;
                font-size: 2.5rem;
                color: #0075BC;
                margin-top: 140px;
                margin-left: 55px;
            }
                @media only screen and (max-width: 37.5em) {
                    .automovil-container-main-top-title {
                        display: flex;
                        font-size: 2rem;
                        color: var(--white);
                        width: max-content;
                        margin-left: auto;
                        margin-right: auto;
                    }}
        .automovil-container-main-title {
            font-style: normal;
            font-weight: 325;
            font-size: 1.2rem;
            color: var(--gray800); 
            margin: 0;
            margin-left: 35px;
            margin-top: 50px;
        }
            @media only screen and (max-width: 37.5em) {
                .automovil-container-main-title {
                    text-align: center;
                    font-size: 0.9rem;
                    margin-left: 0;
                    margin-bottom: -40px;
                    width: 85%;
                    align-self: center;
                }}
        .automovil-container-main-inputs {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-evenly;
            width: 100%;
            margin-top: 50px;
        }
            @media only screen and (max-width: 37.5em) {
                .automovil-container-main-inputs {
                    flex-direction: column;
                    justify-content: center;
                    width: 90%;
                    margin: 0 auto;
                    margin-top: 50px;
                }}
            .automovil-container-main-inputs-list {
                display: flex;
                flex-direction: column;
                width: 46.5%
            }
                @media only screen and (max-width: 37.5em) {
                    .automovil-container-main-inputs-list {
                        width: 100%
                    }}
                .automovil-container-main-inputs-list-label {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.25rem;
                    line-height: 24px;
                    color: #0075BC;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .automovil-container-main-inputs-list-typeList {
                    display: flex;   
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: wrap;
                }
                    .automovil-container-main-inputs-list-typeList-text {
                        font-style: normal;
                        font-weight: 325;
                        font-size: 1.1rem;
                        color: var(--primary700);
                        margin: 0;
                        background: var(--primary200);
                        border-radius: 15px;
                        padding: 5px 10px;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                        @media only screen and (max-width: 37.5em) {
                            .automovil-container-main-inputs-list-typeList-text {
                                margin-top: 10px;
                            }}
                    .automovil-container-main-inputs-list-typeList-textSelected {
                        font-style: normal;
                        font-weight: 325;
                        font-size: 1.1rem;
                        color: var(--white);
                        margin: 0;
                        background: var(--primary500);
                        border-radius: 15px;
                        padding: 5px 10px;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                        @media only screen and (max-width: 37.5em) {
                            .automovil-container-main-inputs-list-typeList-textSelected {
                                margin-top: 10px;
                            }}
                .automovil-container-main-inputs-list-title {
                    font-style: normal;
                    font-weight: 325;
                    font-size: 1.75rem;
                    color: var(--black);
                    margin: 0;
                }
                    @media only screen and (max-width: 37.5em) {
                        .automovil-container-main-inputs-list-title {
                            font-size: 1.5rem;
                            margin-top: 40px;
                        }}
                .automovil-container-main-inputs-list-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 101%;
                    margin-left: -9px;
                }
                    .automovil-container-main-inputs-list-row-inputWrapper {
                        width: 47.5%;
                    }
                .automovil-container-main-inputs-list-inputWrapper {
                    margin-left: -9px;
                }
        .automovil-container-main-buttons {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            margin: 40px 0;
        }
            @media only screen and (max-width: 37.5em) {
                .automovil-container-main-buttons {
                    flex-direction: column;
                }}
            .automovil-container-main-buttons-buttonWrapper {
                width: 20%;
                margin: 0 10px;
            }
                @media only screen and (max-width: 37.5em) {
                    .automovil-container-main-buttons-buttonWrapper {
                        width: 90%;
                        margin: 5px 0;
                    }}