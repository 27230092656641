.offerType-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background: #E8E8E8;
    border-radius: 25px;
}
    @media only screen and (max-width: 37.5em) {
        .offerType-container {
            margin-bottom: 10px;
        }}
    .offerType-container-item {
        display: flex;
        justify-content: center;
        border-radius: 25px;
        padding: 10px 0;
        width: 100%;
        cursor: pointer;
        transition: ease 0.5s all;
        background-color: transparent;
    }
    .offerType-container-itemSel {
        display: flex;
        justify-content: center;
        border-radius: 25px;
        padding: 10px 0;
        width: 100%;
        cursor: pointer;
        transition: ease 0.5s all;
        background-color: var(--secondary400);
    }
        .offerType-container-item-text {
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 32px;
            text-align: center;
            color: var(--secondary400);
            margin: 0;
        }
        @media only screen and (max-width: 37.5em) {
            .offerType-container-item-text {
                font-size: 0.75rem;
            }}
        .offerType-container-item-textSel {
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 32px;
            text-align: center;
            color: #FFFFFF;
            margin: 0;
        }
        @media only screen and (max-width: 37.5em) {
            .offerType-container-item-textSel {
                font-size: 0.75rem;
            }}