.userSelect-container {
    display: flex;
    flex-direction: column;  
    width: 100%;
    overflow-x: hidden;
}
    .userSelect-container-main {
        display: flex;
        flex-direction: column;
        width: 95%;
        align-self: center;
        margin-bottom: 10px;
    }
        .userSelect-container-main-selector {
            display: flex;
            flex-direction: row;
            margin: 10px 0;
        }
            .userSelect-container-main-selector-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
            }
                .userSelect-container-main-selector-item-text {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 0.9rem;
                    color: var(--primary500);
                    margin-left: 5px;      
                }
                .userSelect-container-main-selector-item-circle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 15px;
                    height: 15px;
                    border: 2px solid var(--primary500);
                    border-radius: 25px;
                }
                    .userSelect-container-main-selector-item-circle-inner {
                        width: 11px;
                        height: 11px;
                        border-radius: 25px;
                        background-color: var(--primary500);
                        transition: all 0.5s ease;
                    }
        .userSelect-container-main-inputContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid var(--gray200);
            box-sizing: border-box;
            border-radius: 15px;
            background-color: var(--white);
            padding: 10px;
            width: 351px;
            max-height: 40px;
        }
        .userSelect-container-main-inputContainerDis {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid var(--gray100);
            box-sizing: border-box;
            border-radius: 15px;
            background-color: var(--gray100);
            padding: 10px;
            width: 351px;
            max-height: 40px;
        }
            .userSelect-container-main-inputContainer-input {
                border: none;
                outline: none;
                color: #333333;
                background-color: var(--white);
                margin: 0;
                padding: 0;
                width: 95%;
                -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
            }
            .userSelect-container-main-inputContainer-input:-webkit-autofill,
            .userSelect-container-main-inputContainer-input:-webkit-autofill:hover, 
            .userSelect-container-main-inputContainer-input:-webkit-autofill:focus, 
            .userSelect-container-main-inputContainer-input:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
            }
                .userSelect-container-main-inputContainer-input::placeholder {
                    font-style: normal;
                    font-weight: 300;
                    color: #999999;
                }
            .userSelect-container-main-inputContainer-inputDis {
                border: none;
                outline: none;
                color: #333333;
                background-color: var(--gray100);
                margin: 0;
                padding: 0;
                width: 90%;
                margin-left: 5px;
            }
        .userSelect-container-main-searchContainer {
            margin-top: 20px;
        }
            .userSelect-container-main-searchContainer-title {
                font-style: normal;
                font-weight: 700;
                font-size: 1.5rem;
                color: #333333;
                margin-bottom: 20px;
            }
            .userSelect-container-main-searchContainer-item {
                display: flex;
                flex-direction: row;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                border-bottom-width: 1px;
                border-bottom-color: var(--gray200);
                border-bottom-style: solid;
                padding-bottom: 16px;
                margin-bottom: 17px;
            }
                .userSelect-container-main-searchContainer-item-name {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 0.9rem;
                    color: #333333;
                    margin: 0;
                }
                .userSelect-container-main-searchContainer-item-end {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                    .userSelect-container-main-searchContainer-item-end-email {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 0.9rem;
                        color: #333333;
                        margin: 0;
                    }
                    .userSelect-container-main-searchContainer-item-end-seleccionar {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 0.8rem;
                        color: var(--primary500);
                        margin: 0;
                        margin-left: 20px;
                        cursor: pointer;
                    }
            .userSelect-container-main-searchContainer-emptyList {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
            }
                .userSelect-container-main-searchContainer-emptyList-text {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 1.5rem;
                    color: #333333;
                    margin: 20px 0;
                }
                .userSelect-container-main-searchContainer-emptyList-buttonWrapper {
                    width: 15%;
                }

.userSelect-container-main-inputContainer-loading {
    width: 20px;
    height: 20px;
    border: 2px solid var(--primary500);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}