.accesosRapidos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: var(--primary500);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    width: 116px;
    min-height: 98px;
    cursor: pointer;
}
    .accesosRapidos-container:active {
        transform: scale(0.95);
        background: var(--primary700);
    }
    .accesosRapidos-conainer-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50%;
    }
    .accesosRapidos-container-text {
        height: 30%;
        width: 80%;
        text-align: center;
        color: var(--white);
        padding-bottom: 10px;
    }