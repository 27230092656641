.home-container {
    padding:0;
    margin:0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
}
    .home-container-main {
        display: flex;
        flex-direction: column;
        width: 94%;
        align-self: center;
    }
        @media only screen and (max-width: 37.5em) {
            .home-container-main {
                width: 100%;
                align-self: flex-start;
            }
        }
        .home-container-main-contact {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 24px 0;
        }
            @media only screen and (max-width: 37.5em) {
                .home-container-main-contact {
                    align-self: center;
                }
            }
        .home-container-main-title {
            color: var(--gray700);
            margin-top: 30px;
        }
            @media only screen and (max-width: 37.5em) {
                .home-container-main-title {
                    margin-left: 20px;
                }
            }
        .home-container-main-subContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
            .home-container-main-subContainer-productList {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
                @media only screen and (max-width: 37.5em) {
                    .home-container-main-subContainer-productList {
                        overflow-x: scroll;
                        padding-left: 20px;
                    }
                }
                .home-container-main-subContainer-productList > * { 
                    margin-right: 33px;
                }
            .home-container-main-subContainer-title {
                color: var(--gray700);
                margin-bottom: 24px;
                margin-top: 40px;
            }
                @media only screen and (max-width: 37.5em) {
                    .home-container-main-subContainer-title {
                        margin-left: 20px;
                    }
                }
            .home-container-main-subContainer-list {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
                .home-container-main-subContainer-list > * {
                    margin: 0 10px;
                    margin-bottom: 10px;
                }
                @media only screen and (max-width: 37.5em) {
                    .home-container-main-subContainer-list {
                        overflow-x: scroll;
                        flex-wrap: nowrap;
                        padding-left: 10px;
                    }
                }