.admin-container {
    background-color: var(--adminInicioSesionFondo);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    overflow-y: auto;
}
.admin-subcontainer{
    display: flex; 
    flex-direction: column;
    align-items: center;
    max-width: 427px;
    margin: 20px auto;
}

.admin-container-logo{
    height: 40px;
    margin-bottom: 40px;
}

.admin-sesion-title {
    text-align: center;
    color: var(--adminInicioSesionTexto);
    margin-bottom: 56px;
}
.admin-sesion-subtitle{
    text-align: center;
    color: var(--adminInicioSesionTexto);
    margin-bottom: 32px;
    font-weight: 700;
}
.admin-sesion-container-login{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 80%;
}
.admin-sesion-container-login > *{
    margin-bottom: 32px;
}
.admin-sesion-recuperar-pass{
    text-align: center;
    color: var(--adminInicioSesionOlvido);
    cursor: pointer;
    margin-bottom: 16px;
}

.admin-sesion-marcadores{
    background: var(--primary300);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px 0;
}

.title-marcadores{
    color: var(--gray700);
    margin-top: 12px;
}
.subtitle-marcadores{
    color: var(--gray700);
}

.admin-sesion-inicio {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-sesion-inicio-but {
    width: 75%;
    margin-bottom: 32px;
}

.admin-sesion-marcadores-icon {
    display: flex;
    color: var(--gray700);
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

.admin-sesion-marcadores-text {
    width: 80%;
}

@media only screen and (max-width: 37.5em) {
    .admin-sesion-inicio-but {
        margin-bottom: 0;
    }
    .admin-subcontainer{
        max-width: unset;
        margin: unset;
        margin-top: 50px;
    }
    .admin-sesion-title{
        width: 65%;
    }
    .admin-container-logo {
        height: 40px;
    }
    .admin-sesion-subtitle{
    }
    .admin-sesion-marcadores{
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 90%;
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .title-marcadores{
    }
    .subtitle-marcadores{
    }
    .admin-sesion-recuperar-pass{
    }
    .admin-sesion-inicio {
        width: 90%;
    }
    .admin-sesion-container-login{
        width: 90%;
    }
}