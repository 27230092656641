
.FormFieldPhotoItem-container {
    margin: 5px 0;
}
    @media only screen and (max-width: 37.5em) {
        .FormFieldPhotoItem-container {
            margin: 0;
            margin-right: 5px;
        }}
.FormFieldPhotoItem-containerDes {
    display: flex;
    flex-direction: row;
    background: var(--white);
    border: 2px solid var(--primary500);
    box-sizing: border-box;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    width: 100%;
}
    .FormFieldPhotoItem-containerDes-text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: var(--primary500);
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
.FormFieldPhotoItem-containerRes {
  
}
    .FormFieldPhotoItem-containerRes-imgContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--white);
        border: 0.5px solid var(--gray100);
        box-sizing: border-box;
        border-radius: 25px;
        height: 90px;
        width: 90px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        margin: 0 10px;
    }
        .FormFieldPhotoItem-container-imgContainer-input {
            display: none;
        }
        .FormFieldPhotoItem-containerRes-imgContainer-img {
            height: 90px;
            width: 90px;
            border-radius: 25px;
        }
    .FormFieldPhotoItem-containerRes-editButton {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--primary500);
        border-radius: 25px;
        height: 24px;
        width: 24px;
        margin-bottom: -20px;
        margin-left: auto;
        margin-right: 5px;
        z-index: 100;
        position: relative;
    }