.misReclamos-container {
    
}
    .misReclamos-container-topContainer {
        display: flex;
        background-color: var(--primary500);
        border-bottom-left-radius: 50px;
        padding-top: 30px;
        padding-bottom: 60px;
        position: relative;
        justify-content: space-between;
    }
        @media only screen and (max-width: 37.5em) {
            .misReclamos-container-topContainer {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: var(--white);
                padding-bottom: 0;
                display: none;
            }}
        .misReclamos-container-topContainer-text {
            font-style: normal;
            font-weight: normal;
            font-size: 3rem;
            color: var(--white);
            margin: 0;   
            margin-left: 50px; 
        }
            @media only screen and (max-width: 37.5em) {
                .misReclamos-container-topContainer-text {
                    font-size: 1.75rem;
                    color: var(--primary500);
                    margin-left: 0; 
                    margin-bottom: 20px;
            }}
    .misReclamos-container-mask {
        display: flex;
        background: var(--primary500);
        height: 100px; 
        width: 100%;
        margin-bottom: -75px;
    }
        @media only screen and (max-width: 37.5em) {
            .misReclamos-container-mask {
                display: none;
            }}
        .misReclamos-container-mask-inner {
            display: flex;
            background: var(--gray50);
            height: 100px;
            width: 100%;
            border-top-right-radius: 50px;
        }
    .misReclamos-container-listContainer {
        display: flex;
        flex-direction: column;
        margin-left: 50px;
    }
        @media only screen and (max-width: 37.5em) {
            .misReclamos-container-listContainer {
                margin-left: 20px;
                margin-top: 20px;
            }}
        .misReclamos-container-listContainer-title {
            font-style: normal;
            font-weight: bold;
            font-size: 1.25rem;
            color: #333333;
            margin: 0;
            margin-bottom: 20px;
        }
        .misReclamos-container-listContainer-listReclamos {
            display: flex;
            flex-direction: row;
            overflow-x: scroll;
            padding-bottom: 20px;
        }
            @media only screen and (max-width: 37.5em) {
                .misReclamos-container-listContainer-listReclamos {
                    flex-direction: column;
                }}
            .misReclamos-container-listContainer-listReclamos-item {
                margin-bottom: 0;
            }
                @media only screen and (max-width: 37.5em) {
                    .misReclamos-container-listContainer-listReclamos-item {
                        margin-bottom: 10px;
                    }}
        .misReclamos-container-listContainer-listReclamosEmpty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
            .misReclamos-container-listContainer-listReclamosEmpty-text {
                font-style: normal;
                font-weight: normal;
                font-size: 1.25rem;
                text-align: center;
                color: #999999;
                margin: 10;
            }
            @media only screen and (max-width: 37.5em) {
                .misReclamos-container-listContainer-listReclamosEmpty-text {
                    font-size: 1rem;
                }}
        .misReclamos-container-listContainer-listSeguros {
            display: flex;
            flex-direction: row;
            overflow-x: scroll;
            padding-bottom: 20px;
        }
    .misReclamos-container-lastText {
        font-style: normal;
        font-weight: bold;
        font-size: 1.25rem;
        text-align: center;
        color: var(--primary500);
        margin: 0;
        margin-top: 20px;
        cursor: pointer;
        margin-bottom: 20px;
    }
        @media only screen and (max-width: 37.5em) {
            .misReclamos-container-lastText {
                font-size: 0.9rem;
            }}
    .misReclamos-container-notFoundAlertCon {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
        .misReclamos-container-notFoundAlertCon-text {
            font-style: normal;
            font-weight: normal;
            font-size: 1.25rem;
            line-height: 1.5rem;
            text-align: center;
            color: #333333;
            margin: 0;
            margin-top: 30px;
            margin-bottom: 10px;
        }