.estilotextarea {
    width: 95%;
    height:250px;
    background-color: #EAEAEA;
    border-radius: 25.0514px;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 21px;
    padding-top: 15px;
    padding-left: 15px;
    color: #000;
    outline: none;
    border-color: transparent;
}

.container-card-suggest{
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: -120px 5px 0px 15px;
}

.label-description{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--primary500);
    margin-top: 0;
}

.title-suggest-product{
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: var(--black);
}

.container-form-suggest-product{
    display: flex;
    flex-direction: column;
    /* width: 425px; */
    flex: 2.5;
}

.modal-text-title{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: var(--gray300);
}

.modal-text-subtitle{
    color: var(--black);
}

.modal-text-subtitle-title{
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: var(--black);
}

.custom-select select {
    background-color: transparent;
    border-bottom: 1px solid #999999;
    width:  100%;
    margin-left: 10px;
    padding-left: 0px;
    outline: none;
  }
  
  /* style the items (options), including the selected item: */
  .select-items div,.select-selected { 
    color: #888888;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    font-size: 1.25rem;
    line-height: 24px;
  }

  
  .custom-select select:hover {
    background-color: rgba(0, 0, 0, 0.01);
  }

.suggest-title-data-personal{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    color: var(--primary500);
    align-items: center;
}

@media only screen and (max-width: 620px) {
    .container-card-suggest{
        margin-top: -85px;
    }
    .estilotextarea {
        width: 300px;
        height: 250px;
        font-size: 16px;
        line-height: 20px;
    }
    .title-suggest-product{
        font-size: 18px;
        line-height: 23px;
    }
    .container-form-suggest-product{
        width: 320px;
    }
    .modal-text-title{
        font-size: 20px;
        line-height: 25px;
    }

    .modal-text-subtitle-title{
        font-size: 15px;
        line-height: 19px;
    }

}

@media only screen and (max-width: 340px ){
    .container-card-suggest{
        margin-top: -85px;
    }
    .estilotextarea {
        width: 270px;
        height: 200px;
        font-size: 16px;
        line-height: 20px;
    }
    .title-suggest-product{
        font-size: 16px;
        line-height: 21px;
    }
    .container-form-suggest-product{
        width: 290px;
    }
}