.card-item-name{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #333333;
}

.card-item-model{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #333333;
}

.card-item-value{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}

.container-card-sugeridos{
    margin-left: 20px;
}