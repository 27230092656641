.terminosPage-container {
    display: flex;
    flex-direction: column;  
    overflow-x: hidden;
    width: 100%;
}
    .terminosPage-container-main {
        width: 93%;
        margin: 10px auto;
    }
        .terminosPage-container-main-text1 {
            font-style: normal;
            font-weight: 325;
            font-size: 2rem;
            color: var(--primary500);
            margin: 0;
        }
        .terminosPage-container-main-text2 {
            font-style: normal;
            font-weight: 325;
            font-size: 1.5rem;
            color: var(--gray500);
            margin: 0;
        }
        .terminosPage-container-main-text3 {
            font-style: normal;
            font-weight: 325;
            font-size: 1.5rem;
            color: var(--gray600);
            margin: 0;
        }
        .terminosPage-container-main-text4 {
            font-style: normal;
            font-weight: 325;
            font-size: 1.7rem;
            color: var(--primary500);
            margin: 0;
            margin-bottom: 40px;
            margin-top: 20px;
        }
        .terminosPage-container-main-text5 {
            font-style: normal;
            font-weight: 325;
            font-size: 1rem;
            color: var(--gray500);
            margin: 0;
        }
            .terminosPage-container-main-text5 ul {
                font-style: normal;
                font-weight: 325;
                font-size: 1rem;
                color: var(--primary500);
                margin: 0;
                padding: 0;
            }
            .terminosPage-container-main-text5 a {
                font-style: normal;
                font-weight: 325;
                font-size: 1rem;
                color: var(--primary700);
                margin: 0;
                padding: 0;
                text-decoration: none;
                cursor: pointer;
            }