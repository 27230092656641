.customTextInput1-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.customTextInput1-container-label {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.customTextInput1-container-label-requiered {
    font-style: normal;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 24px;
    color: red;
    margin: 0;
}

.customTextInput1-container-inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.customTextInput1-container-inputContainer-input {
    border: none;
    background-color: transparent;
    font-style: normal;
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 24px;
    color: var(--black);
    width: 100%;
}

.customTextInput1-container-inputContainer-input:focus {
    outline: none;
}

.customTextInput1-container-line {
    height: 1px;
    width: 100%;
    background-color: #999999;
}

.customTextInput1-container-inputContainer-input::placeholder {
    font-style: normal;
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 24px;
    color: var(--gray300);
}

.customTextInput1-container-inputContainer-input:placeholder-shown {
    background-color: transparent;
}

.estilotextarea1 {
    width: 96.5%;
    height: 250px;
    background-color: #EAEAEA;
    border-radius: 25.0514px;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 21px;
    margin: 0;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    color: #000;
    outline: none;
    border-color: transparent;
    resize: none;
}

.label-description{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--primary500);
    margin-top: 0;
}