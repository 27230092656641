.customTextInput-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
}
    .customTextInput-container-label {
        display: flex;
        flex-direction: row;
    }
        .customTextInput-container-label-title {
            color: var(--tertiary500);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .customTextInput-container-label-requiered {
            color: var(--statusErrorPrimary);
            margin: 1000;
        }
    .customTextInput-container-inputContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
        .customTextInput-container-inputContainer-preValue {
            font-style: normal;
            font-weight: normal;
            font-size: 1.25rem;
            color: #0075BC;
            margin: 0;
        }
        .customTextInput-container-inputContainer-verified {
            font-style: normal;
            font-weight: normal;
            font-size: 1.25rem;
            color: #27AE60;
            margin: 0;
        }
        .customTextInput-container-inputContainer-cambiar {
            font-style: normal;
            font-weight: normal;
            font-size: 1.25rem;
            color: var(--primary500);
            margin: 0;
            cursor: pointer;
        }
            .customTextInput-container-inputContainer-cambiar:active {
                transform: scale(0.9);
            }
        .customTextInput-container-inputContainer-iconContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-left: auto;
        }
        .customTextInput-container-inputContainer-input {
            border: none;
            background-color: transparent;
            font-style: normal;
            font-weight: 300;
            font-size: 1.25rem;
            line-height: 24px;
            color: var(--black);
            width: 100%;
            font-family: Lato, sans-serif;
            padding: 10px;
        }
            .customTextInput-container-inputContainer-input:focus {
                outline: none;
            }
            .customTextInput-container-inputContainer-input::placeholder {
                font-style: normal;
                font-weight: 300;
                font-size: 1rem;
                line-height: 1.5rem;                
                color: var(--gray300);
                font-family: Lato, sans-serif;
            }
            .customTextInput-container-inputContainer-input-adminLogin::placeholder{
                font-style: normal;
                font-weight: 300;
                font-size: 1.25rem;
                line-height: 1.5rem;                
                color: var(--gray200);
                font-family: Lato, sans-serif;
            }
            .customTextInput-container-inputContainer-input:placeholder-shown {
                background-color: transparent;
            }
    .customTextInput-container-line {
        height: 1px;
        width: 100%;
        background-color: #999999;
    }
    .customTextInput-container-errorMessage {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 24px;
        color: #FE1039;
        margin: 0;
        margin-bottom: -24px;
    }
    .customTextInput-container-underText {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: -14px;
    }
        .customTextInput-container-underText-text {
            font-style: normal;
            font-weight: 300;
            font-size: 0.8125rem;
            color: var(--primary500);
            margin: 0;
        }

        input[type='date']{
            text-transform: uppercase;
        }